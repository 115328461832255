// DEPENDENCIES
import { Route, Switch } from "react-router-dom";
// LAYOUT
import { BaseLayout } from "layouts";
// VIEWS
import AuthenticationRoutes from "views/public/Authentication";
import CategoryRoutes from "views/public/Categories";
import NotFound from "./404";

const PublicRoutes = () => {
  return (
    <BaseLayout>
      <Switch>
        <Route path="/categorie" component={CategoryRoutes} />
        <Route path="/auth" component={AuthenticationRoutes} />
        <Route exact path="/" component={CategoryRoutes} />
        <Route component={NotFound} />
      </Switch>
    </BaseLayout>
  );
};

export default PublicRoutes;
