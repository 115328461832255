import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api/storage";

const uploadFile = async (file, path, name, getBase64) => {
  const data = new FormData();
  data.append("file", file);
  const result = await axios.post(`${API_URL}/upload`, data, {
    params: {
      path,
      name,
      getBase64,
    },
  });
  return result.data;
};

const getFile = async (path) => {
  if (!path || path === "null") return null;
  const result = await axios.get(`${API_URL}/upload`, {
    params: {
      path,
    },
  });
  return result.data;
};

const getDirectory = async (path) => {
  if (!path || path === "null") return null;
  const result = await axios.get(`${API_URL}/upload`, {
    params: {
      path,
    },
  });
  return result.data;
};

export { uploadFile, getFile, getDirectory };
