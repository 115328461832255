import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { loginUser, useAuthState, useAuthDispatch } from "context/auth";

import { COLORS } from "utils";
import { darken } from "helpers";
import { Alert, Loader } from "components";
import { useTranslation } from "react-i18next";

const StyledButton = styled.button`
  background: ${COLORS.primary};
  padding: 0.5rem 2rem;
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  color: ${COLORS.white};
  font-family: "MontserratBold";
  font-size: 1rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
  &:hover {
    background: ${darken(COLORS.primary, 10)};
  }
  &:active {
    opacity: 0;
  }
`;
const StyledForm = styled.form`
  flex: 1;
  margin: 1rem;
  padding: 2rem;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-family: "MontserratBold";
    margin: 0;
    color: #00abf0;
    margin-bottom: 2rem;
  }
  > span {
    font-size: 0.7rem;
    margin-left: auto;
    color: red;
    margin: 1rem 0;
    display: block;
  }
  fieldset {
    border: none;
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;
    max-width: 400px;
    label {
      display: block;
      margin-bottom: 1rem;
      color: ${COLORS.primary};
      display: flex;
      text-align: center;
      justify-content: center;
      font-family: "MontserratBold";
      span {
        font-size: 0.7rem;
        margin-left: auto;
        color: red;
      }
    }
    input {
      padding: 0.5rem 1rem;
      width: 100%;
      border-radius: 2rem;
      border: 2px solid ${COLORS.gray};
      outline: none;
      color: #7d8598;
      &:focus {
        border-color: ${darken(COLORS.gray, 10)};
      }
    }
  }
  div {
    text-align: right;
    a {
      text-decoration: none;
      color: #00abf0;
      font-size: 0.7rem;
    }
  }
  button {
    margin: 2rem auto;
  }
`;
const StyledRememberMe = styled.div`
  max-width: 200px;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: auto;
      cursor: pointer;
    }
    span {
      margin: 0;
      margin-left: 0.25rem;
      font-family: "Montserrat";
      font-size: 0.6rem;
      color: ${COLORS.darkGray};
      cursor: pointer;
    }
  }
`;

const LoginForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [user, setUser] = useState({
    email: null,
    password: null,
  });
  const [error, setError] = useState(null);

  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();

  const handleChange = (e) => {
    const newUser = user;
    newUser[e.target.name] = e.target.value;
    setUser(newUser);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await loginUser(dispatch, user);
      if (!response.user) return;
      history.push("/admin");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  if (loading) return <Loader />;

  return (
    <StyledForm onSubmit={(e) => handleSubmit(e)}>
      {error && <Alert type="error" message={error} />}
      <fieldset>
        <label htmlFor="email">{t("login.email")}</label>
        <input name="email"
               type="email"
               onChange={(e) => handleChange(e)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="password">{t("login.password")}</label>
        <input
          name="password"
          type="password"
          onChange={(e) => handleChange(e)}
        />
      </fieldset>
      <StyledRememberMe>
        <label>
          <input name="rememberUser" type="checkbox" />
          <span>{t("login.remember")}</span>
        </label>
      </StyledRememberMe>
      <StyledButton>{t("login.action")}</StyledButton>
      <div>
        <a href="/">{t("login.forgottenPassword")}</a>
      </div>
    </StyledForm>
  );
};

export default LoginForm;
