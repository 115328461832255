import { Table } from "components/common/Cms";
import { COLORS } from "utils";
import { useEffect, useState } from "react";
import { getAllUsers } from "services/user.service";
import styled from "styled-components";

const Masonry = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
  margin: 0 1%;
  @media (max-width: 768px) {
    flex: 0 0 98%;
  }
  a {
    color: ${COLORS.primary};
    font-family: "MontserratBold";
    font-size: 0.7rem;
    margin-left: auto;
    padding: 1rem 0;
    text-decoration: none;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-family: "MontserratBold";
  font-size: 0.7rem;
  margin-bottom: 1rem;
  color: ${COLORS.primary};
`;

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    async function init() {
      const { data } = await getAllUsers();
      if (data.success) setUsers(data.users);
    }
    init();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        marginLeft: "-1%",
        marginRight: "-1%",
        alignItems: "stretch",
      }}
    >
      <Masonry>
        <Title>Ultimi utenti registrati</Title>
        <Table
          data={users.filter((user) => user.role !== "superadmin").slice(0, 3)}
          headers={["ID", "Email", "Nome", "Cognome", "Ruolo", "Creato il"]}
        />
        <a href="/admin/utenti">Vedi tutti</a>
      </Masonry>
      <Masonry>
        <Title>Ultimi articoli redazionali inseriti</Title>
        <Table data={[]} />
      </Masonry>
      <Masonry>
        <Title>Ultimi ordini effettuati</Title>
        <Table data={[]} />
      </Masonry>
      <Masonry>
        <Title>Ultime ricerche effettuate</Title>
        <Table data={[]} />
      </Masonry>
    </div>
  );
};

export default AdminUsers;
