import styled from "styled-components";

const Container = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  /*padding: 1rem;*/
  padding-bottom: 0;
  background: #ffffff;
  border-bottom: 2px solid #f5f6fb;
`;

export default Container;
