// DEPENDENCIES
import styled from "styled-components";
// UTILS
import { COLORS } from "utils";
// COMPONENTS
import Header from "layouts/public/components/Header/Header";
import Footer from "layouts/public/components/Footer/Footer";

const Main = styled.main`
  background: ${COLORS.lightGray};
  flex: 1;
  padding-bottom: 3rem;
`;

const BaseLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default BaseLayout;
