import { Wrapper, Row, Title, Description } from "./Banner.styled";
import { images } from "utils";
import axios from "axios";
import {useEffect, useState} from "react";
import styled from "styled-components";

const Thumbnail = styled.div`
  width: 15%;
  margin: 0 1rem 0 0;
  position: relative;
  img {
    width: 100%;
    font-size: 0;
    &.loading {
      opacity: 0;
    }
  }
`;
const Banner = ({ title, description, image, item }) => {
    const [img, setImg] = useState(images.placeholder);
    const [loadingImage, setLoading] = useState(true);

    useEffect(() => {
        async function fetchThumbnail() {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/storage`, {
                    path: item.thumbnail,
                })
                .then((res) => setImg(res.data))
                .then(() => setLoading(false))
                .catch(() => {
                    setImg(images.placeholder);
                    setLoading(false);
                });
        }
        if (item && item.thumbnail && item.thumbnail !== "null") fetchThumbnail();
        else setLoading(false);
    }, [item]);
  return (
    <Wrapper>
      <Row>
          <Thumbnail>
              <img
                  src={img}
                  alt={title}
                  className={loadingImage ? "loading" : null}
              />
          </Thumbnail>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Row>
    </Wrapper>
  );
};

export default Banner;
