import { Route, Switch, useRouteMatch } from "react-router-dom";
import Signup from "./Signup";

const AuthenticationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/signup`}>
        <Signup />
      </Route>
    </Switch>
  );
};

export default AuthenticationRoutes;
