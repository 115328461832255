import styled from "styled-components";

const StyledRarity = styled.div`
  margin-top: auto;
  padding-bottom: 0.5rem;
  width: fit-content;
  > div {
    background: #00abf0;
    padding: 0.5rem 1rem;
    display: flex;
    width: auto;
    justify-content: space-between;
    color: #ffffff;
    span {
      font-family: "MontserratBold";
      font-size: 0.7rem;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0;
    margin-top: 0.5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const StyledStar = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 0.25rem;
  color: ${({ active }) => (active ? "#00abf0" : "#7d8598")};
  @media (max-width: 768px) {
    font-size: 5.5vw;
  }
`;

const Rarity = ({ rarity }) => {
  const ArticleRarity = () => {
    const rarityMax = 10;
    let rarityMap = [];
    for (let idx = 0; idx < rarityMax; idx++) {
      let isFull = false;
      if (idx < rarity) {
        isFull = true;
      }
      rarityMap.push({
        value: idx,
        isFull,
      });
    }
    return (
      <div
        style={{
          padding: "0 1rem",
        }}
      >
        {rarityMap.map((star, index) => (
          <StyledStar active={star.isFull} key={index}>
            &#9733;
          </StyledStar>
        ))}
      </div>
    );
  };

  return (
    <StyledRarity title={`Rarità ${rarity} su 10`}>
      <div>
        <span>Comune</span>
        <span>Raro</span>
        <span>Unico</span>
      </div>
      <ul>
        <ArticleRarity rarity={rarity} />
      </ul>
    </StyledRarity>
  );
};

export default Rarity;
