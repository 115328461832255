import { useState } from "react";

import { Container } from "components";
import Series from "./components/Series/Series";
import Categories from "./components/Categories/Categories";

const AdminArchive = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <Container height="100%">
      <Categories
        selectedCategory={selectedCategory}
        onSelectCategory={selectCategory}
      />
      <Series selectedCategory={selectedCategory} />
    </Container>
  );
};

export default AdminArchive;
