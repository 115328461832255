import { COLORS } from "utils";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { authService } from "services";
import styled from "styled-components";
import { darken, lighten } from "helpers";

const Form = styled.form`
  align-items: center;
  background: ${COLORS.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: ${COLORS.darkGray};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 600px;
  padding: 1rem;
  width: 100%;
  fieldset {
    border: none;
    display: flex;
    font-family: "Montserrat";
    font-size: 0.8rem;
    margin: 1rem 0;
    padding: 0;
    width: 100%;
    label {
      font-family: "MontserratBold";
      flex: 1;
      padding: 0 1rem;
      input {
        border: 1px solid ${COLORS.gray};
        border-radius: 1rem;
        color: ${COLORS.darkGray};
        margin-top: 0.5rem;
        outline: none;
        padding: 0.5rem 1rem;
        width: 100%;
        &:focus {
          border-color: ${COLORS.primary};
        }
      }
    }
  }
  button {
    background: ${COLORS.primary};
    border: none;
    border-radius: 2rem;
    color: ${COLORS.white};
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 0.8rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    transition: background 0.3s ease;
    &:hover {
      background: ${darken(COLORS.primary, 5)};
    }
    &:active {
      opacity: 0;
    }
  }
  div {
    align-items: center;
    color: ${lighten(COLORS.error, 10)};
    background: ${lighten(COLORS.error, 40)};
    display: flex;
    font-family: "Montserrat";
    font-size: 0.8rem;
    justify-content: center;
    padding: 1rem;
    margin: 1rem;
    width: 100%;
  }
`;

const SignupForm = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm: "",
  });

  async function handleChange(e) {
    const newUser = { ...user };
    newUser[e.target.name] = e.target.value;
    setUser(newUser);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { data } = await authService.register(user);
    if (data.success) {
      setErrorMessage();
      history.push("/");
    } else setErrorMessage(data.message);
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <fieldset>
        <label htmlFor="firstName">
          Nome:
          <input
            name="firstName"
            type="text"
            value={user.firstName}
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="lastName">
          Cognome:
          <input
            name="lastName"
            type="text"
            value={user.lastName}
            onChange={(e) => handleChange(e)}
          />
        </label>
      </fieldset>
      <fieldset>
        <label htmlFor="email">
          Email:
          <input
            name="email"
            type="email"
            value={user.email}
            onChange={(e) => handleChange(e)}
          />
        </label>
      </fieldset>
      <fieldset>
        <label htmlFor="password">
          Password:
          <input
            name="password"
            type="password"
            value={user.password}
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="confirm">
          Conferma password:
          <input
            name="confirm"
            type="password"
            value={user.confirm}
            onChange={(e) => handleChange(e)}
          />
        </label>
      </fieldset>
      {errorMessage && <div>{errorMessage}</div>}
      <button type="submit">Registrati</button>
    </Form>
  );
};

export default SignupForm;
