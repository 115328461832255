import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { darken, lighten } from "helpers";
import ArticleList from "../Articles/ArticleList";
import axios from "axios";
import Loader from "components/common/Loader/Loader";
import { COLORS, images } from "utils";
import Switch from "react-input-switch";

const StyledSaveBtn = styled.button`
  border: none;
  border-radius: 1rem;
  margin: 0 auto;
  margin-top: auto;
  padding: 0.25rem 1rem;
  background: ${() => COLORS.primary};
  color: ${() => COLORS.white};
  font-family: "MontserratBold";
  font-size: 0.8rem;
  line-height: 1;
  align-self: center;
  display: block;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: ${darken(COLORS.primary, 10)};
  }
  &:active {
    opacity: 0;
  }
`;
const StyledTextarea = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  & label {
    border: none;
    margin-left: 1rem;
    font-size: 0.7rem;
    color: ${() => COLORS.darkGray};
    font-family: "MontserratBold";
  }
  & textarea {
    border-radius: 1rem;
    margin: 0;
    display: flex;
    margin-top: 0.5rem;
    resize: none;
    flex: 1;
    outline: none;
    line-height: 1.5;
    border: ${() => `2px solid ${COLORS.lightGray}`};
    padding: 1rem;
    font-family: "Montserrat";
    font-size: 0.7rem;
    color: ${() => COLORS.darkGray};
    line-height: 1.75;
    height: 100%;
    &:focus {
      border: 2px solid ${lighten(COLORS.darkGray, 30)};
    }
  }
`;
const StyledFieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  &[name="name"] {
    width: 65%;
    padding-left: 1rem;
    > input {
      min-height: none;
      width: 100%;
    }
  }
  & label {
    border: none;
    margin-left: 1rem;
    font-size: 0.7rem;
    color: #7d8598;
    /* flex: 0.3; */
    font-family: "MontserratBold";
  }
  & input {
    border-radius: 1rem;
    margin-top: 0.5rem;
    flex: 1;
    padding: 0.25rem 1rem;
    color: #7d8598;
    outline: none;
    max-width: 300px;
    border: 2px solid #f5f6fb;
    font-family: "Montserrat";
    &:focus {
      border: 2px solid ${lighten("#7d8598", 30)};
    }
    &[type="file"] {
      border: none;
    }
  }
  & textarea {
    border-radius: 1rem;
    margin-top: 0.5rem;
    resize: none;
    flex: 1;
    outline: none;
    line-height: 1.5;
    border: 2px solid #f5f6fb;
    padding: 1rem;
    font-family: "Montserrat";
    font-size: 0.7rem;
    color: #7d8598;
    line-height: 1.75;
    &[name="longDescription"] {
      height: 100%;
    }
    &:focus {
      border: 2px solid ${lighten("#7d8598", 30)};
    }
  }
`;
const StyledThumbnail = styled.div`
  margin-bottom: 1rem;
  width: 45%;
  position: relative;
  > div {
    align-items: center;
    width: 100%;
    & div {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      & img {
        width: 100%;
        height: auto;
        transition: 0.3s ease;
      }
      & input {
        opacity: 0;
        position: absolute;
        inset: 0;
        cursor: pointer;
        width: 100%;
      }
      &:hover {
        & img {
          opacity: 0.8;
        }
      }
    }
  }
  & span {
    font-family: "Montserrat";
    position: absolute;
    bottom: 2.5rem;
    color: #7d8598;
    margin-left: 2rem;
    left: 100%;
    font-size: 0.8rem;
  }
  & button {
    font-family: "Montserrat";
    position: absolute;
    bottom: 0.5rem;
    cursor: pointer;
    margin-left: 2rem;
    left: 100%;
    font-size: 0.8rem;
    border-radius: 1rem;
    padding: 0.125rem 1rem;
    border: none;
    background-color: #f5f6fb;
    color: #7d8598;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${darken("#f5f6fb", 5)};
    }
    &:active {
      opacity: 0;
    }
  }
`;
const StyledHeading = styled.h3`
  /* margin-top: 1rem; */
  color: #00abf0;
  padding: 0.25rem 1rem;
  font-family: "MontserratBold";
  font-size: 1rem;
  border-bottom: 2px solid #f5f6fb;
  display: flex;
  align-items: center;
  width: 100%;
`;

const EditSeries = ({ series, updateSeries }) => {
  const [fileName, setFileName] = useState(null);
  const [currentSeries, setSeries] = useState(null);
  const [articles, setArticles] = useState([]);
  const [modifiedArticles, setModified] = useState([]);
  const [thumbnail, setThumbnail] = useState(images.placeholder);

  const seriesRef = useRef(null);

  const { isLoading, error, refetch } = useQuery(
    "articles",
    () =>
      fetch(
        `${process.env.REACT_APP_API_URL}/api/cms/series/${series.id}/articles`
      )
        .then((res) => res.json())
        .then((data) => {
          setArticles(data);
          setModified(data);
          return data;
        }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    const fetchThumbnail = async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/storage`,
        { path: series.thumbnail }
      );
      setThumbnail(data || "https://via.placeholder.com/600");
    };
    if (series && series.thumbnail && series.thumbnail !== "null")
      fetchThumbnail();
  }, [series]);

  useEffect(() => {
    if (series) {
      setSeries(series);
      refetch();
    } else {
      setSeries(null);
    }
  }, [series, refetch]);

  const createArticle = useCallback(async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/cms/series/${currentSeries.id}/articles`
    );
    if (data.success) {
      refetch();
    }
  }, [refetch, currentSeries]);

  const createSeparator = useCallback(async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/cms/series/${currentSeries.id}/separator`
    );
    if (data.success) {
      refetch();
    }
  }, [refetch, currentSeries]);

  const deleteArticle = useCallback(
    async (id) => {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/cms/articles/${id}`
      );
      if (data.success) {
        refetch();
      }
    },
    [refetch]
  );

  const updateArticles = useCallback(async () => {
    for await (const article of modifiedArticles) {
      const formData = new FormData();
      formData.append("name", article.name);
      formData.append("thumbnail", article.thumbnail);
      formData.append("description", article.description);
      formData.append("position", article.position);
      formData.append("rarity", article.rarity);
      formData.append("seriesId", article.seriesId);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/cms/articles/${article.id}`,
        formData
      );
    }
    refetch();
  }, [modifiedArticles, refetch]);

  const onUpdateBatch = useCallback(
    (data) => {
      setModified(data);
    },
    [setModified]
  );

  const onUpdateArticle = useCallback(
    (article) => {
      setModified((prev) =>
        prev.map((item) => (item.id === article.id ? article : item))
      );
    },
    [setModified]
  );

  const onEmitGallery = useCallback(
    (articleId, gallery) => {
      const filtered = articles.filter((article) => article.id !== articleId);
      let article = articles.find((article) => article.id === articleId);
      article.gallery = gallery;
      setArticles([...filtered, article]);
    },
    [articles]
  );

    function onChangePublish(pubblic) {
        const updatedSeries = {
            ...currentSeries,
        };
        updatedSeries[`published`] = pubblic;
        setSeries(updatedSeries);
    }

  const handleChange = (e) => {
    const newState = {
      ...currentSeries,
    };
    if (e.target.name === "thumbnail")
      newState[e.target.name] = e.target.files[0];
    else newState[e.target.name] = e.target.value;
    setSeries(newState);
    if (e.target.files) {
      setFileName(e.target.files[0].name);
    }
  };

  const reorderArticles = useCallback(
    async (article) => {
      const filteredArticles = articles
        .sort((a, b) => a.position - b.position)
        .filter((item) => item.id !== article.id);
      const prevSegment = filteredArticles.slice(0, article.position);
      const nextSegment = filteredArticles.slice(article.position);
      const updatedArticles = [...prevSegment, article, ...nextSegment].map(
        (item, index) => ({ ...item, position: index })
      );
      setModified(updatedArticles);
    },
    [setModified, articles]
  );

  if (isLoading) return <Loader />;

  if (error) return `Error: ${error.message}`;

  if (!currentSeries) return null;

  return (
    <div
      style={{
        width: "100%",
        background: "#ffffff",
        display: "flex",
        padding: "1rem 2rem",
        flexDirection: "column",
        marginTop: "auto",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          background: "#ffffff",
          borderRadius: "1rem",
          display: "flex",
          padding: "1rem 0",
          flexWrap: "wrap",
          height: "22%",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "1rem",
            flex: 0.4,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <StyledThumbnail>
              <div>
                <div>
                  <img
                    src={thumbnail}
                    alt={currentSeries.name}
                    width={100}
                    height={100}
                  />
                  <input
                    type="file"
                    name="thumbnail"
                    ref={seriesRef}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <span>{fileName}</span>
                <button
                  onClick={() => {
                    if (seriesRef.current) {
                      seriesRef.current.click();
                    }
                  }}
                >
                  Sfoglia
                </button>
              </div>
            </StyledThumbnail>
            <StyledFieldset name="name">
              <label>Nome Serie</label>
              <input
                type="text"
                name="name"
                value={currentSeries.name}
                onChange={(e) => handleChange(e)}
              />
            </StyledFieldset>
          </div>
          <StyledFieldset>
            <label>Descrizione breve</label>
            <textarea
              value={currentSeries.shortDescription}
              name="shortDescription"
              onChange={(e) => handleChange(e)}
            />
          </StyledFieldset>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          <StyledTextarea>
            <label>Descrizione lunga</label>
            <textarea
              value={currentSeries.longDescription}
              name="longDescription"
              onChange={(e) => handleChange(e)}
            />
          </StyledTextarea>
        </div>
      </div>
      <StyledHeading>Articoli</StyledHeading>
      <ArticleList
        series={currentSeries}
        articles={articles}
        deleteArticle={deleteArticle}
        reorderArticles={reorderArticles}
        updateBatch={onUpdateBatch}
        emitGallery={onEmitGallery}
        createArticle={createArticle}
        createSeparator={createSeparator}
        updateArticle={onUpdateArticle}
      />
        <StyledFieldset>
            <label className="switch-label-article">Pubblicato</label>
            <Switch
                name="published"
                value={currentSeries.published}
                onChange={(e) => onChangePublish(e)}
                styles={{
                    track:{
                        borderRadius: '13px'
                    },
                    trackChecked: {
                        backgroundColor: '#31ABF0',
                        borderRadius: '13px'
                    },
                    button: {
                        right: '16px'
                    },
                    buttonChecked: {
                        left: '16px'
                    }
                }}
                className="switch-article"
            >
            </Switch>
        </StyledFieldset>
      <StyledSaveBtn
        onClick={() => {
          updateSeries(currentSeries);
          updateArticles(articles);
        }}
      >
        Salva
      </StyledSaveBtn>
    </div>
  );
};

export default EditSeries;
