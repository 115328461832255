import styled from "styled-components";
import {FONTS} from "utils";

const Navigation = styled.nav`
  width: 100%;
  margin-top: 0.2rem;
  @media (max-width: 768px) {
    display: none;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    list-style: none;
    li {
      a {
        padding: 1.3rem 1.62rem;
        text-decoration: none;
        text-transform: uppercase;
        color: #98a4b7;
        font-family: ${FONTS.h4};
        font-weight: 600;
        font-size: 16px;
        font-style: italic;
        position: relative;
        overflow: hidden;
        display: block;
        /*&:before {
          content: "";
          width: 100%;
          height: 3px;
          background: #00abf0;
          position: absolute;
          bottom: 0;
          left: -100%;
          transition: 0.3s ease-out;
        }
        &:hover {
          &:before {
            left: 0;
          }
        }*/
        &:hover {
        color: #fcbb01;
        }
      }
    }
  }
`;

export default Navigation;
