// DEPENDENCIES
import styled from "styled-components";
// UTILS
import { lighten } from "helpers";

const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const Label = styled.label`
  border: none;
  margin-left: 1rem;
  font-size: 1rem;
  color: #7d8598;
  flex: 0.3;
  font-family: "MontserratBold";
`;

const Input = styled.input`
  border-radius: 1rem;
  margin: 0;
  padding: 0.25rem 1rem;
  margin-top: 0.5rem;
  outline: none;
  flex: 1;
  border: 2px solid #f5f6fb;
  font-family: "Montserrat";
  color: #7d8598;
  &:focus {
    border: 2px solid ${lighten("#7d8598", 30)};
  }
`;

const ArticleName = ({ name, onChange }) => {
  return (
    <Fieldset>
      <Label>Nome</Label>
      <Input
        type="text"
        name="name"
        value={name}
        onChange={(e) => onChange(e)}
      />
    </Fieldset>
  );
};

export default ArticleName;
