import styled from "styled-components";

const StyledContainer = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  max-width: 1024px;
  margin: 0 auto;
  padding: ${({ noPadding }) => (noPadding ? "0" : "1rem")};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;

const Container = ({ width, height, flexDirection, noPadding, children }) => {
  return (
    <StyledContainer
      width={width}
      height={height}
      flexDirection={flexDirection}
      noPadding={noPadding}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
