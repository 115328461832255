// DEPENDENCIES
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// CONTEXT
import Fonts from "assets/fonts";
import { AuthProvider } from "context/auth";
// VIEWS
import AdminRoutes from "views/admin";
import PublicRoutes from "views/public";
import Login from "views/Login";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Fonts />
          <Switch>
            <Route path="/admin" component={AdminRoutes} />
            <Route path="/login" component={Login} />
            <Route path="/" component={PublicRoutes} />
          </Switch>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
