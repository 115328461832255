import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api/auth/";

const register = async (user) => {
  return await axios.post(API_URL + "register", user);
};

const login = async (user) => {
  const { data } = await axios.post(API_URL + "login", user);
  if (data.user && data.user.accessToken) {
    const savedUser = {
      id: data.user.id,
      email: data.user.email,
      role: data.user.role,
      created_at: data.user.created_at,
    };
    localStorage.setItem("gd_current_user", JSON.stringify(savedUser));
  }
  return data;
};

const logout = () => {
  localStorage.removeItem("gd_current_user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("gd_current_user"));
};

export { register, login, logout, getCurrentUser };
