import { COLORS } from "utils";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
  border: ${() => `2px solid ${COLORS.primary}`};
  background: ${() => COLORS.white};
  position: absolute;
  top: 1rem;
  left: 50%;
  z-index: 10;
  opacity: 0;
  transform: translate(-50%, -100%);
  transition: all 0.3s ease;
  p {
    color: ${() => COLORS.primary};
    font-family: "Montserrat";
    font-size: 1rem;
    margin: 0;
    padding: 1rem;
  }
  &.visible {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const AdminNotification = ({ message }) => {
  const [visible, setVisible] = useState(false);
  const [prevMsg, setMessage] = useState(null);

  useEffect(() => {
    if (message) {
      setVisible(true);
      setMessage(message);
    } else {
      setVisible(false);
    }
  }, [message]);

  return (
    <StyledComponent className={visible ? "visible" : null}>
      {prevMsg && <p onClick={() => setVisible(false)}>{prevMsg}</p>}
    </StyledComponent>
  );
};

export default AdminNotification;
