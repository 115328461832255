import { COLORS, FONTS } from "utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons';

const Component = styled.div`
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    width: auto;
    background: ${COLORS.blue};
    display: flex;
    overflow: auto;
    font-family: ${FONTS.body};
    font-weight: normal;
    font-size: 16px;
    color: ${COLORS.lightGray};
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 20px;
    h4 {
        color: #00ABF0;
        font-family: ${FONTS.h4};
        font-weight: 600;
        font-size: 25px;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 20px;
        text-align: center;
        font-style: italic;
    }
    .row{
        width: 100%;
    }
    .links {
        display: inline-block;
        margin-bottom: 20px;
        padding: 0 50px 0 0;
        vertical-align: top;
    }
    .item a{
        text-decoration: none;
        color: ${COLORS.lightGray};
        font-family: ${FONTS.body};
        font-weight: normal;
        font-size: 16px;
    }
    .item a:hover {
        color: ${COLORS.primary};
    }
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 200px;
        max-width: 200px;
    }
    .ourShop {
        img{
            margin-top: 10px;
        }
    }
    .socialLink { 
        list-style: none;
        display: flex;
        li a{
            text-align: center;
            color: ${COLORS.white};
            font-family: ${FONTS.body};
            font-weight: normal;
            display: inline-block;
            margin-right: 15px;
            font-size: 30px;
            transition: all .2s ease-in-out;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        svg {
            border: 3px solid white;
            padding: 6px;
            width: 31px;
            height: 28px;
            border-radius: 25px;
        }
    }
    @media (max-width: 768px) {
    padding-right: 0;
     .row{
        text-align: center;
     }
     .socialLink{
        justify-content: center;
        padding: 0;
      }
     img{
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          display: block;
          margin-left: auto;
          margin-right: auto;
     }
     div:nth-child(1) > a:nth-child(1) > img:nth-child(1){
        max-height: 200px;
        max-width: 200px;
     }
     .nav{
        justify-content: center;
     }
     .links{
        padding: 0;
     }
  }
`;


const PreFooter2 = () => {
    const { t } = useTranslation();

    return (
        <Component>
            <div className="row">
            <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                <h4>{t("footer.informazioni")}</h4>
                <ul className="footer links">
                    <li className="nav item"><a href="https://gianedavicollezioni.com/privacy-policy-cookie-restriction-mode/">Privacy e Cookie</a></li>
                    <li className="nav item"><a href="https://gianedavicollezioni.com/contact/">Contattaci</a></li>
                    <li className="nav item"><a href="https://gianedavicollezioni.com/catalogsearch/advanced/" data-action="advanced-search">Ricerca avanzata</a></li>
                    <li className="nav item"><a href="https://gianedavicollezioni.com/spedizioni/">Spedizioni</a></li>
                    <li className="nav item"><a href="https://gianedavicollezioni.com/pagamenti/">Pagamento</a></li>
                    <li className="nav item"><a href="https://gianedavicollezioni.com/fiere-ed-eventi/">Fiere ed Eventi</a></li>
                </ul>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                <div>
                    <a className="" href="https://gianedavicollezioni.com/tv" title="Gian&amp;Davi TV">
                        <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/Gianedavi_TV.svg" alt="Gianedavi TV" />
                    </a>
                </div>
            </div>
            <div className="footer-three col-xs-12 col-sm-12 col-md-2">
                <div>
                    <a href="https://gianedavicollezioni.com/archivio" title="Gian&amp;Davi Archivio">
                        <img  src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/Archivio_Gianedavi.svg" alt="Gianedavi Archivio" />
                    </a>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-2 ourShop">
                <h4>I nostri Shop</h4>

                <div>
                    <a href="http://stores.ebay.it/gianedavi-collezioni-shop" title="Gian&amp;Davi on Ebay" target="_blank" rel="nofollow noopener">
                        <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/EBay_logo.svg" alt="Ebay" />
                    </a>
                </div>

                <div>
                    <a href="https://www.amazon.it/s?marketplaceID=APJ6JRA9NG5V4&amp;me=A17KKV8P0ZDW97&amp;merchant=A17KKV8P0ZDW97&amp;redirect=true" title="Gian&amp;Davi on Amazon" target="_blank" rel="nofollow noopener">
                        <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/Amazon-Logo.svg" alt="Amazon" />
                    </a>
                </div>

                <div>
                    <a href="https://www.cardmarket.com/it/Pokemon/Users/gianedavicollezioni" title="Gian&amp;Davi on Cardmarket" target="_blank" rel="nofollow noopener">
                        <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/Cardmarket.svg" alt="Cardmarket"/>
                    </a>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                <h4>Seguici su</h4>

                <ul className="socialLink" itemScope="" itemType="http://schema.org/Organization">
                    <link itemProp="url" href="http://www.gianedavicollezioni.com" />
                    <li>
                        <a itemProp="sameAs" href="https://www.facebook.com/Gianedavicollezioniofficial" title="Gian&amp;Davi on Facebook" target="_blank" rel="nofollow noopener">
                            <FontAwesomeIcon icon={faFacebookF} />
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a itemProp="sameAs" href="https://www.instagram.com/gianedavicollezioni/" title="Gian&amp;Davi on Instagram" target="_blank" rel="nofollow noopener">
                            <FontAwesomeIcon icon={faInstagram} />
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-2 ">

                <h4>Corrieri</h4>
                <div>
                    <img className="langIT" src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/corrieri/sped_IT.svg" alt="Corrieri" />
                </div>
            </div>
            </div>
        </Component>
    );
};
export default PreFooter2;