// DEPENDENCIES
import { useState, useEffect, Fragment, useCallback } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
// COMPONENTS
import { Container, Loader } from "components/common";
import { List, ListItem } from "library/public/pages/archive";
import { Breadcrumbs } from "library/public/common";

const Category = () => {
  const { slug } = useParams();

  const [series, setSeries] = useState(() => []);
  const [subCategories, setSubCategories] = useState(() => []);

  const { isLoading, error, data } = useQuery("categories", () =>
    fetch(`${process.env.REACT_APP_API_URL}/api/cms/slug/${slug.replaceAll("_", " ")}`)
        .then((res) => res.json())
        .then((data) => data[0])
  );

  const fetchSubCategories = useCallback(() => {
    if (data && !subCategories.length)
      fetch(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${data.id}/children/active`
      )
        .then((res) => res.json())
        .then((data) => setSubCategories(data));
  }, [data, subCategories]);

  const fetchSeries = useCallback(() => {
    if (data && !series.length)
      fetch(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${data.id}/series/active`
      )
        .then((res) => res.json())
        .then((data) => setSeries(data));
  }, [data, series]);

  useEffect(() => {
    fetchSubCategories();
    fetchSeries();
  }, [fetchSubCategories, fetchSeries]);

  if (isLoading) return <Loader />;
  if (error) return `Error: ${error.message}`;

  function renderCategoryItem() {
    return subCategories
      .sort((a, b) => a.position - b.position)
      .map((cat) => (
        <ListItem
          item={cat}
          key={cat.id}
          baseUrl={`/categorie`}
        />
      ));
  }

  function renderSeriesItem() {
    return series
      .sort((a, b) => a.position - b.position)
      .map((cat) => (
        <ListItem
          item={cat}
          key={cat.id}
          baseUrl={`/categorie/${slug}/serie`}
        />
      ));
  }

  /*function orderItem() {
    let items = series.concat(subCategories);
    items
        .sort((a,b)=> a.position - b.position)
        .map(cat => {
          if (cat.is_series == 0)
            return <ListItem item={cat} key={cat.id} baseUrl={`/categorie/${slug}/serie`}/>
          return <ListItem item={cat} key={cat.id} baseUrl={`/categorie`}/>
        });
  }*/

  // If current category only contains a single series
  // with the same name, redirect to that series page.
/*  if (
    !subCategories.length &&
    series.length === 1 &&
    series[0].name === data.name
  ) {
    return (
      <Redirect
        to={`/categorie/${slug}/serie/${series[0].name
          .replaceAll(" - ", "-")
          .replaceAll(" ", "-")}`}
      />
    );
  }*/

  const microdata = {
    "@context": "https://schema.org/",
    "@type": "Thing",
    name: data.name,
    description: data.description,
    url: window.location.href,
  };

  return (
    <Fragment>
      <Helmet>
        <title>{data.name} | Gian&Davi Collezioni</title>
        <script type="application/ld+json">{JSON.stringify(microdata)}</script>
      </Helmet>
      <Breadcrumbs currentId={data.parentId} />
      <Container flexDirection="column">
        <List>{renderCategoryItem()} {renderSeriesItem()}</List>
        {/*<list>{orderItem()}</list>*/}
        {/*<List>{renderSeriesItem()}</List>*/}
      </Container>
    </Fragment>
  );
};

export default Category;
