import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLogo = styled.div`
  padding: 0;
  margin: 0;
  max-width: 100%;
  margin: 1rem;
  display: block;
  & img {
    width: 100%;
    height: auto;
  }
`;

const NavbarLogo = ({ src }) => {
  return (
    <StyledLogo>
      <Link to="/" title="Sito">
        <img src={src} alt="Logo" />
      </Link>
    </StyledLogo>
  );
};

export default NavbarLogo;
