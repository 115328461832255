import { COLORS } from "utils";
import styled from "styled-components";
import Copy from "./Copy";
import PreFooter1 from "./PreFooter1";
import PreFooter2 from "./PreFooter2";
import PreFooter3 from "./PreFooter3";

const Component = styled.footer`
  background: ${COLORS.blue};
  display: block;
  width: 100%;
`;

const Footer = () => {
  return (
    <Component>
        <PreFooter1 />
        <PreFooter2 />
        <PreFooter3 />
      <Copy />
    </Component>
  );
};

export default Footer;
