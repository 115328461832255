import styled from "styled-components";
import cart from "assets/svg/G_D_btn_shopper.svg";
import user from "assets/svg/G_D_btn_lego.svg";
import { COLORS, FONTS } from "utils";
import { lighten } from "helpers";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 1rem;
  }
`;
const Menu = styled.div`
  display: none;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease;
  height: 2rem;
  margin-right: auto;
  z-index: 30;
  &:hover div {
    box-shadow: 1px 1px 5px 1px RGBa(0, 0, 0, 0.3);
  }
  div {
    background: ${COLORS.primary};
    box-shadow: 1px 1px 3px RGBa(0, 0, 0, 0.4);
    border-radius: 2px;
    height: 3px;
    transition: 0.4s ease;
    width: 2.25rem;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  &.active {
    transform: rotatey(180deg);
    div {
      transform: rotatey(180deg) rotatex(360deg);
      @media (max-width: 768px) {
        background: ${COLORS.white};
      }
      &:first-child {
        margin-top: 10px;
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        margin-top: -15px;
        transform: rotate(45deg);
      }
    }
  }
  @media (max-width: 768px) {
    display: block;
    margin-left: 10px;
  }
`;
const Logo = styled.a`
  display: block;
  width: 448px;
  max-width: 448px;
  margin-left: 31.6%;
  margin-top: -2px;
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  @media (max-width: 768px) {
     margin-left: 0;
  }
`;
const Actions = styled.div`
  margin-top: 18px;
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
     margin-top: 0;
  }
`;
const Button = styled.a`
  background: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 3rem;
  height: 3rem;
  border: none;
  cursor: pointer;
  margin: 0 0.4rem;
  text-decoration: none;
  &:active {
    background: white;
  }
  @media (max-width: 768px) {
    a {
      width: 2rem;
      height: 2rem;
    }
  }
`;
const Account = styled(Button)`
  background-image: url(${user});
  width: 35px;
  height: 35px;
   @media (max-width: 768px) {
   display: none;
  }
`;
const Cart = styled(Button)`
  background-image: url(${cart});
  width: 35px;
  height: 35px;
`;
const Link = styled.a`
  align-items: center;
  align-self: center;
  color: ${COLORS.primary};
  cursor: pointer;
  display: flex;
  font-family: ${FONTS.small};
  font-family: 0.8rem;
  justify-content: center;
  margin-left: auto;
  text-decoration: none;
`;
const MobileMenu = styled.nav`
  background: rgba(0, 0, 0, 0.7);
  inset: 0;
  position: fixed;
  width: 100%;
  display: block;
  z-index: -100;
  &.open {
    z-index: 20;
  }
  div {
    background: ${COLORS.primary};
    color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    height: 100%;
    inset: 0;
    padding: 4rem 1rem 1rem;
    position: absolute;
    left: -100%;
    transition: 0.3s ease-out;
    width: 90%;
    ul {
      list-style: none;
      margin: 0;
      padding: 1rem 0;
      width: 100%;
      li {
        a {
          color: ${COLORS.white};
          display: block;
          font-family: ${FONTS.body};
          font-size: 1rem;
          padding: 0.5rem;
          text-decoration: none;
        }
      }
      + ul {
        border-top: 1px solid ${lighten(COLORS.primary, 10)};
      }
    }
    button {
      background: ${COLORS.white};
      border: none;
      border-radius: 2rem;
      color: ${COLORS.primary};
      font-family: ${FONTS.body};
      font-size: 0.8rem;
      line-height: 1;
      margin: 0 auto;
      margin-top: auto;
      padding: 0.5rem 1rem;
      width: fit-content;
      &:active {
        opacity: 0;
      }
    }
  }
  &.open {
    div {
      left: 0;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export { Container, Menu, Logo, Actions, Account, Cart, Link, MobileMenu };
