const archive = require("./G_D_btn__Archivio.svg");
const archiveHover = require("./G_D_btn__Archivio_Roll.svg");
const blog = require("./G_D_btn__Redazione.svg");
const blogHover = require("./G_D_btn__Redazione_Roll.svg");
const dashboard = require("./G_D_btn__Dashboard.svg");
const dashboardHover = require("./G_D_btn__Dashboard_Roll.svg");
const magento = require("./G_D_btn__Magento.svg");
const magentoHover = require("./G_D_btn__Magento_Roll.svg");
const orders = require("./G_D_btn__Ordini.svg");
const ordersHover = require("./G_D_btn__Ordini_Roll.svg");

const Home = require("assets/svg/home.svg").default;

export {
  archive,
  archiveHover,
  blog,
  blogHover,
  dashboard,
  dashboardHover,
  magento,
  magentoHover,
  orders,
  ordersHover,
  Home,
};
