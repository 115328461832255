import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useAuthState } from "context/auth";
import { CmsLayout } from "layouts";

import Dashboard from "./Dashboard/index";
import AdminArchive from "./Archive";
import AdminUsers from "./Users";
import Orders from "./Orders";
import Blog from "./Blog";

const AdminRoutes = () => {
  const { path } = useRouteMatch();
  const {
    userDetails: { role },
    token,
  } = useAuthState();

  function isNotAdmin(role) {
    return role !== "admin" && role !== "superadmin";
  }

  if (!token && isNotAdmin(role))
    return <Redirect to={{ pathname: "/login" }} />;

  return (
    <CmsLayout>
      <Switch>
        <Route exact path={`${path}`} component={Dashboard} />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/archivio`} component={AdminArchive} />
        <Route exact path={`${path}/blog`} component={Blog} />
        <Route exact path={`${path}/ordini`} component={Orders} />
        <Route exact path={`${path}/utenti`} component={AdminUsers} />
      </Switch>
    </CmsLayout>
  );
};

export default AdminRoutes;
