import { Route, Switch, useRouteMatch } from "react-router-dom";
import Blogs from "./Blogs";

const BlogRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <Blogs />
      </Route>
    </Switch>
  );
};

export default BlogRoutes;
