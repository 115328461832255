import SeriesListItem from "./SeriesListItem";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";

const StyledComponent = styled.div`
  /* max-height: 20%; */
  overflow: auto;
`;

const SeriesList = ({ list, selectSeries, selectedSeries, deleteSeries }) => {
  const [orderedItems, setItems] = useState([...list]);
  const [dragId, setDragId] = useState();

  useEffect(() => {
    setItems(list);
  }, [list]);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const updateBatchSeries = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/api/cms/categories/${list[0].categoryId}/series`,
      { series: orderedItems }
    );
  };

  const handleDrop = async (ev) => {
    const dragBox = orderedItems.find((box) => box.id === parseInt(dragId));
    const dropBox = orderedItems.find((box) => box.id === parseInt(ev.currentTarget.id));

    const dragBoxIndex = orderedItems.indexOf(dragBox);
    const dropBoxIndex = orderedItems.indexOf(dropBox);

    const newBoxState = [...orderedItems];

    let cutOut = newBoxState.splice(dragBoxIndex, 1)[0];
    newBoxState.splice(dropBoxIndex, 0, cutOut);
    newBoxState.map((item, index) => (item.position = index));

    setItems(newBoxState);
    updateBatchSeries(newBoxState);
  };

  return (
    <StyledComponent>
      {orderedItems
        .sort((a, b) => a.position - b.position)
        .map((series, index) => {
          const isSelected = selectedSeries && selectedSeries.id === series.id;
          return (
            <SeriesListItem
              isSelected={isSelected}
              callback={() => selectSeries(series.id)}
              deleteSeries={deleteSeries}
              key={index}
              series={series}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
            />
          );
        })}
    </StyledComponent>
  );
};

export default SeriesList;
