import React from "react";
import styled from "styled-components";

const StyledStarWrapper = styled.div`
  margin-top: 0.5rem;
  padding-left: 1rem;
  label {
    margin: 0;
    display: block;
    margin-bottom: 0.5rem;
  }
  div {
    display: flex;
  }
  > span {
    display: block;
    color: #7d8598;
    font-size: 0.8rem;
    margin-top: 1rem;
  }
`;
const StyledStar = styled.span`
  color: ${({ isFull }) => (isFull ? "#03B5FF" : "#7d8598")};
  display: block;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  transition: 150ms ease;
  cursor: pointer;
`;

const ArticleRarity = ({ fileName, rarity, onChangeRarity }) => {
  const renderRarity = () => {
    let rarityArray = [];
    for (let idx = 1; idx <= 10; idx++) {
      rarityArray.push({
        index: idx,
        isFull: idx <= rarity,
      });
    }
    return rarityArray.map((item) => (
      <StyledStar
        key={item.index}
        isFull={item.isFull}
        onClick={() => {
          onChangeRarity(item.index);
        }}
      >
        &#9733;
      </StyledStar>
    ));
  };

  return (
    <StyledStarWrapper title={`Rarità ${rarity} su 10`}>
      <label>Rarità:</label>
      <div>{renderRarity()}</div>
      <span>{fileName}</span>
    </StyledStarWrapper>
  );
};

export default ArticleRarity;
