import { useState, useEffect } from "react";
import styled from "styled-components";
import { darken } from "helpers";
import CategoryTitle from "./CategoryTitle";
import { COLORS, images } from "utils";
import EditCategoryPreview from "./EditCategory/EditCategoryPreview";
import { getFile } from "services/storage.service";
import Switch from "react-input-switch";

const Container = styled.div`
  margin-top: auto;
  padding-right: 1rem;
`;

const UserActions = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  
  margin-left: 3rem;
  > button {
    border: none;
    border-radius: 1rem;
    color: ${() => COLORS.white};
    cursor: pointer;
    font-family: "MontserratBold";
    font-size: 0.7rem;
    line-height: 1;
    margin: 0 0.5rem;
    padding: 0.25rem 1rem 0.3rem;
    transition: background 0.3s ease;
    &:active {
      opacity: 0;
    }
  }
`;
const DeleteButton = styled.button`
  background: ${() => COLORS.error};
  &:hover {
    background: ${() => darken(COLORS.error, 10)};
  }
`;
const SaveButton = styled.button`
  background: ${() => COLORS.primary};
  &:hover {
    background: ${() => darken(COLORS.primary, 10)};
  }
`;
const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
`;
const Label = styled.label`
  border: none;
  margin-left: 1rem;
  font-size: 0.7rem;
  color: #7d8598;
  font-family: "MontserratBold";
  flex: 0.3;
`;
const TextInput = styled.input`
  border: none;
  border-radius: 1rem;
  margin-top: 0.5rem;
  flex: 1;
  padding: 0.25rem 1rem;
  color: #7d8598;
  font-size: 0.7rem;
  outline: none;
  font-family: "Montserrat";
  border: 2px solid #fff;
  &:focus {
    border: 2px solid ${darken("#f5f6fb", 10)};
  }
`;
const TextArea = styled.textarea`
  border: none;
  border-radius: 1rem;
  margin-top: 0.5rem;
  resize: none;
  min-height: 10rem;
  font-family: "Montserrat";
  font-size: 0.75rem;
  color: #7d8598;
  flex: 1;
  outline: none;
  border: 2px solid #fff;
  padding: 1rem;
  font-family: "Montserrat";
  font-size: 0.7rem;
  color: #7d8598;
  line-height: 1.75;
  &:focus {
    border: 2px solid ${darken("#f5f6fb", 10)};
  }
`;

const EditCategory = ({ selectedCategory, updateCategory, deleteCategory }) => {
  const [currentCategory, setCategory] = useState(selectedCategory);
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState(images.placeholder);
  const [cover, setCover] = useState(images.placeholder);

  useEffect(() => {
    let thumbnail = null,
      cover = null;

    const getImages = async () => {
      thumbnail = await getFile(selectedCategory.thumbnail);
      cover = await getFile(selectedCategory.coverImage);
      setThumbnail(thumbnail);
      setCover(cover);
    };

    if (selectedCategory) {
      getImages();
      setThumbnail(thumbnail);
      setCover(cover);
      setCategory(selectedCategory);
      setTitle(selectedCategory.name);
    }
  }, [selectedCategory]);

  const handleChange = (e) => {
    let newState = { ...currentCategory };
    newState[e.target.name] = e.target.value;
    setCategory(newState);
  };

  function onChangePublish(pubblic) {
    const updatedCategory = {
      ...currentCategory,
    };
    updatedCategory[`published`] = pubblic;
    setCategory(updatedCategory);
  }


  const onUpdateFile = (sender, file) => {
    const updatedCategory = {
      ...currentCategory,
    };
    updatedCategory[`upload_${sender}`] = file;
    setCategory(updatedCategory);
  };

  if (!selectedCategory || !currentCategory) return null;

  return (
    <Container>
      <CategoryTitle fontSize="0.8rem">{title}</CategoryTitle>
      <Fieldset>
        <Label htmlFor="name">Titolo</Label>
        <TextInput
          type="text"
          name="name"
          value={currentCategory.name}
          onChange={(e) => handleChange(e)}
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="description">Descrizione</Label>
        <TextArea
          name="description"
          value={currentCategory.description}
          onChange={(e) => handleChange(e)}
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="thumbnail">Anteprima</Label>
        <EditCategoryPreview
          name={selectedCategory.name}
          inputName="thumbnail"
          thumbnail={thumbnail}
          emitImage={(sender, file) => {
            onUpdateFile(sender, file);
          }}
        />
        <Label htmlFor="cover">Copertina</Label>
        <EditCategoryPreview
          name={selectedCategory.name}
          inputName="cover"
          thumbnail={cover}
          emitImage={(sender, file) => {
            onUpdateFile(sender, file);
          }}
        />
      </Fieldset>
      <Fieldset>
        <Label className="switch-label" htmlFor="description">Pubblicato</Label>
      </Fieldset>
        <Switch
            name="published"
            value={currentCategory.published}
            onChange={(e) => onChangePublish(e)}
            styles={{
              track:{
                borderRadius: '13px'
              },
              trackChecked: {
                backgroundColor: '#31ABF0',
                borderRadius: '13px'
              },
              button: {
                right: '16px'
              },
              buttonChecked: {
                left: '16px'
              }
            }}
            className="switch"
        >
        </Switch>
      <UserActions>
        <SaveButton
          onClick={() => {
            updateCategory(currentCategory);
          }}
        >
          Salva
        </SaveButton>
        <DeleteButton
          onClick={() => {
            deleteCategory(currentCategory);
            setThumbnail(null);
          }}
        >
          Elimina
        </DeleteButton>
      </UserActions>
    </Container>
  );
};

export default EditCategory;
