import { COLORS, FONTS } from "utils";
import styled from "styled-components";
import logo from "assets/img/logo-footer.svg";

const Component = styled.div`
  align-items: center;
  background: ${COLORS.primary};
  color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.body};
  font-size: 10px;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  p {
    margin: 0 0 0.5rem;
    text-align: center;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Logo = styled.a`
  display: block;
  width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
`;


const Copy = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Component>
        <Logo href="/">
            <img src={logo} alt="Gian&Davi Collezioni" />
        </Logo>
      <p>
        Gianedavi Collezioni di Davide Volontà - Via A. De Gasperi 25B 10024
        Moncalieri, TO - Italia - P.IVA 10213320012 - R.E.A. TO 1114226
      <br />
        +39 3408326015 - Copyright © {currentYear} Gian&amp;Davi collezioni. All
        rights reserved.
      </p>
    </Component>
  );
};
export default Copy;
