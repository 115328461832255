import { useEffect, useState } from "react";
import axios from "axios";
import { images } from "utils";
import { Wrapper, Thumbnail, Title } from "./ListItem.styled";

const ListItem = ({ item, baseUrl }) => {
  const [img, setImg] = useState(images.placeholder);
  const [loadingImage, setLoading] = useState(true);

  useEffect(() => {
    async function fetchThumbnail() {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/storage`, {
          path: item.thumbnail,
        })
        .then((res) => setImg(res.data))
        .then(() => setLoading(false))
        .catch(() => {
          setImg(images.placeholder);
          setLoading(false);
        });
    }
    if (item && item.thumbnail && item.thumbnail !== "null") fetchThumbnail();
    else setLoading(false);
  }, [item]);

  if (!item) return null;

  return (
    <Wrapper
      href={`${baseUrl}/${item.name
        .replaceAll(" - ", "_-_")
        .replaceAll(" ", "_")}`}
    >
      <Thumbnail>
        <img
          src={img}
          alt={item.name}
          className={loadingImage ? "loading" : null}
        />
      </Thumbnail>
      <Title>{item.name}</Title>
    </Wrapper>
  );
};

export default ListItem;
