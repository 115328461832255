// DEPENDENCIES
import { useState, useEffect } from "react";
import styled from "styled-components";
// UTILS
import { COLORS, images } from "utils";
// ASSETS
import chevron from "assets/icons/chevron-right.png";
import axios from "axios";
import { darken } from "helpers";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;
const Expand = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  background-image: url(${chevron});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
  transform: rotate(90deg);
  transition: transform 0.3s ease-out;
  &.active {
    transform: rotate(-90deg);
  }
`;
const Details = styled.div`
  height: 0;
  max-height: 0;
  position: relative;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  &.is-open {
    max-height: 10000000000px;
    padding: 1rem;
    transition: 0.5s ease;
    opacity: 1;
    height: auto;
  }
  p {
    width: 100%;
    font-family: "Montserrat";
    font-size: 0.7rem;
    color: ${COLORS.gray};
    text-align: center;
  }
`;
const Input = styled.div`
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
  position: relative;
  transition: opacity 0.3s ease;
  border: 2px solid #7d8598;
  border-radius: 10px;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  input {
    position: absolute;
    inset: 0;
    width: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
  &:after {
    content: "+";
    display: flex;
    position: absolute;
    inset: 0;
    width: 100%;
    color: #7d8598;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    line-height: 1;
    font-family: "Montserrat";
    opacity: 1;
    transition: 0.3s ease 0.1s;
  }
  &:hover {
    opacity: 0.7;
  }
`;
const Thumbnail = styled.div`
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  span {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: grab;
    border: 2px solid ${COLORS.gray};
    border-radius: 10px;
    &:active {
      cursor: grabbing;
      opacity: 0.8;
    }
    img {
      width: 100%;
      max-width: 100%;
      font-size: 0;
    }
  }
`;
const Delete = styled.button`
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: none;
  border-radius: 50%;
  top: -0.5rem;
  right: -0.5rem;
  background: ${COLORS.error};
  color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 1;
  font-family: "Montserrat";
  font-size: 0.7rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
  z-index: 10;
  line-height: 0;
  transition: background 0.3s ease;
  &:hover {
    background: ${darken(COLORS.error, 10)};
  }
`;

const ArticleDetails = ({ articleId, emitThumbnail, emitGallery }) => {
  const [open, setOpen] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [dragId, setDragId] = useState();

  useEffect(() => {
    async function fetchGallery() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery`
      );
      if (data) {
        const newGallery = [];
        for (const picture in data) {
          newGallery.push(data[picture]);
        }
        setGallery(newGallery);
      }
    }
    fetchGallery();
  }, [articleId]);

  useEffect(() => {
    if (gallery[0]) emitThumbnail(gallery[0].url);
    else emitThumbnail(images.placeholder);
  }, [gallery, emitThumbnail]);

  async function deletePicture(id) {
    // API call to delete specified image from gallery
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery/${id}`
    );
    if (data.success) {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery`
      );
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery`
      );
      if (data) {
        const newGallery = [];
        for (const picture in data) {
          newGallery.push(data[picture]);
        }
        setGallery(newGallery);
      }
    }
  }

  async function uploadPicture(files) {
    const formData = new FormData();
    formData.append("gallery", files[0]);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery/${gallery.length}`,
      formData
    );
    if (data) {
      const thumbnail = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/storage`,
        { path: data.file.path }
      );
      setGallery([
        ...gallery,
        {
          id: gallery.length,
          url: thumbnail.data,
          position: gallery.length,
        },
      ]);
    }
  }

  async function deleteAndRenameFiles(gallery) {
    const newOrder = gallery.map((item) => item.id);
    await axios.put(
      `${process.env.REACT_APP_API_URL}/api/cms/articles/${articleId}/gallery/order`,
      {
        gallery: newOrder,
      }
    );
  }

  const FileInput = () => {
    return (
      <Input>
        <input
          type="file"
          name="gallery"
          onChange={(e) => uploadPicture(e.target.files)}
        />
      </Input>
    );
  };

  const onHandleDrag = async (ev) => {
    if (!dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      setDragId(target.id);
    }
  };
  const onHandleDrop = async (ev) => {
    if (dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      const dragBox = gallery.find((box) => box.id === parseInt(dragId));
      const dropBox = gallery.find((box) => box.id === parseInt(target.id));

      if (dragBox && dropBox && dragBox.parentId === dropBox.parentId) {
        const dragBoxIndex = gallery.indexOf(dragBox);
        const dropBoxIndex = gallery.indexOf(dropBox);

        const newBoxState = [...gallery];
        let cutOut = newBoxState.splice(dragBoxIndex, 1)[0];
        newBoxState.splice(dropBoxIndex, 0, cutOut);
        newBoxState.map((item, index) => (item.position = index));
        setGallery(newBoxState);
        deleteAndRenameFiles(newBoxState);
        emitGallery(articleId, newBoxState);
      }
      setDragId(null);
    }
  };

  const Picture = ({ picture }) => {
    const [draggable, setDraggable] = useState(false);

    return (
      <Thumbnail
        id={picture.id}
        draggable={draggable}
        onDragOver={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setDraggable(false);
        }}
        onDragStart={onHandleDrag}
        onDrop={onHandleDrop}
      >
        <span>
          <img src={picture.url} alt="" />
        </span>
        <Delete
          onClick={(e) => {
            e.stopPropagation();
            setDraggable(false);
            deletePicture(picture.id);
          }}
          key={picture.id}
        >
          ×
        </Delete>
      </Thumbnail>
    );
  };

  return (
    <Container>
      <Expand
        onClick={() => setOpen(!open)}
        className={open ? "active" : null}
      />
      <Details className={open ? "is-open" : null}>
        {gallery
          .sort((a, b) => a.position - b.position)
          .map((picture) => (
            <Picture key={picture.id} picture={picture} />
          ))}
        {gallery.length < 30 && <FileInput />}
        <p>
          L'immagine in prima posizione verrà utilizzata come copertina
          dell'articolo.
        </p>
      </Details>
    </Container>
  );
};

export default ArticleDetails;
