import styled from "styled-components";
import ArticleListItem from "./ArticleListItem";
import ArticleListSeparator from "./ArticleListSeparator";

const StyledList = styled.div`
  margin-top: 4rem;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding: 0 1rem;
    margin-top: 1rem;
  }
`;

const ArticleList = ({ list }) => {
  return (
    <StyledList>
      {list
        .sort((a, b) => a.position - b.position)
        .map((article) => {
          if (article.isSeparator)
            return <ArticleListSeparator article={article} key={article.id} />;
          else return <ArticleListItem article={article} key={article.id} />;
        })}
    </StyledList>
  );
};

export default ArticleList;
