import { Container } from "components";
import React from "react";
import LoginForm from "./components/LoginForm";
import logoImg from "assets/img/logo.svg";
import styled from "styled-components";
import { COLORS } from "utils";

const StyledComponent = styled.img`
  display: block;
  font-size: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  margin-top: 5rem;
  max-width: 400px;
`;

const Logo = () => {
  return (
    <StyledComponent
      src={logoImg}
      alt="Gian&Davi Collezioni"
      title="Gian&Davi Collezioni"
      width={500}
      height={500}
    />
  );
};

const Login = () => {
  return (
    <div style={{ background: COLORS.lightGray, height: "100%" }}>
      <Container flexDirection="column">
        <Logo />
        <LoginForm />
      </Container>
    </div>
  );
};

export default Login;
