import styled from "styled-components";
import { COLORS, FONTS } from "utils";

const Paragraph = styled.p`
  color: ${COLORS.darkGray};
  flex: 1;
  font-family: ${FONTS.body};
  line-height: 1.5;
  margin: 0;
  margin-top: ${(props) => (props.noTrim ? "1rem" : "3rem")};
  white-space: ${(props) => (props.noTrim ? "normal" : "pre-wrap")};
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export default Paragraph;
