import styled from "styled-components";
import { COLORS, FONTS } from "utils";
import search from "assets/svg/search.svg";

const SearchBar = styled.fieldset`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: -25px;
  padding: 0;
  border: none;
  font-size: 1rem;
  transition: max-width 0.3s ease;
  /*&.focused {
    label {
      input {
        color: ${COLORS.primary};
      }
    }
  }*/
  label {
    width: 100%;
    border: 1px solid ${COLORS.gray};
    border-radius: 2rem;
    display: flex;
    height: 35px;
    align-items: center;
    overflow: hidden;
    padding: 0.5rem 6px;
    transition: border-radius 0.3s ease;
    input {
      flex: 1;
      border: none;
      outline: none;
      text-align: right;
      font-family: ${FONTS.body};
      color: ${COLORS.gray};
      font-size: 15px;
      line-height: 1;
    }
  }
    button {
      border: none;
      outline: none;
      width: 2rem;
      height: 35px;
      cursor: pointer;
      background: transparent;
      background-image: url(${search});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 22px;
      position: relative;
      left: 604px;
      bottom: 36px;
      /*&:active {
        background: white;
      }*/
    }
  @media (max-width: 768px) {
    text-align: right;
    height: 40px;
    margin: 15px 44px 20px 44px;
    width: 78%;
    font-size: 0.8rem;
    label {
      span {
        margin: 0 0.5rem;
      }
    }
    input {
        width: 78%;
    }
  }
`;

export default SearchBar;
