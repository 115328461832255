import styled from "styled-components";

const Component = styled.div`
  position: relative;
  background-color: #03ABF1;
  width: 100%;
  height: 30px;
  text-align: right;
  padding: 0 16.8%;
  a{
    margin: 10px 11px;
    color: #fff;
    font-size: 12px;
    display: inline;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  div {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
  @media (max-width: 768px) {
      display: none;
  }
`;

const TopPanel = () => {

    return (
    <Component>
        <div>
            <a href="https://gianedavicollezioni.com/customer/account/login/">Accedi</a>
            <a href="https://gianedavicollezioni.com/customer/account/create/">Crea un Account</a>
        </div>
    </Component>
    );
};

export default TopPanel;