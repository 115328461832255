import CategoryList from "./CategoryList";
import EditCategory from "./EditCategory";
import CategoryContainer from "./CategoryContainer";
import CategoryTitle from "./CategoryTitle";
import styled from "styled-components";
import addDirectory from "assets/icons/add_directory.svg";
import { useState } from "react";
import Modal from "components/common/Modal";
import axios from "axios";
import { useQuery } from "react-query";
import Loader from "components/common/Loader/Loader";
import { COLORS } from "utils";
import AdminNotification from "components/common/AdminNotification";
import { uploadFile } from "services/storage.service";

const CategoryAdd = styled.button`
  height: 40px;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 0.7rem;
  margin-left: 1.5rem;
  color: ${() => COLORS.primary};
  background: transparent;
  background-image: url(${addDirectory});
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 30px;
  font-size: 0.7rem;
  &:active {
    background: transparent;
  }
`;

const formatCategories = (data) => {
  let state = [];
  let oldArray = [...data];

  while (oldArray.length) {
    oldArray.forEach((cat, index) => {
      if (!cat.parentId) {
        state.push({
          ...cat,
          children: [],
        });
        oldArray.splice(index, 1);
      } else findNestedParent(state);

      function findNestedParent(parent) {
        const parentCat = parent.find((parent) => parent.id === cat.parentId);
        if (parentCat) {
          parentCat.children.push({
            ...cat,
            children: [],
          });
          oldArray.splice(index, 1);
        } else {
          parent.forEach((item) => findNestedParent(item.children));
        }
      }
    });
  }

  return state;
};

const Categories = ({ selectedCategory, onSelectCategory }) => {
  const [categories, setCategories] = useState();
  const [showModal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [toDeleteName, setToDeleteName] = useState(null);
  const [adminMessage, setAdminMessage] = useState(null);

  const { isLoading, error, data, refetch } = useQuery(
    "categories",
    () =>
        fetch (`${process.env.REACT_APP_API_URL}/api/cms/categories`)
        .then((res) => res.json())
        .then((data) => {
          setCategories(data);
          return formatCategories(data);
        }),
    { refetchOnWindowFocus: false, enabled: true }
  );

  const addCategory = async () => {
    async function createCategory() {
      const newCategory = {
        name: "Nuova categoria",
        thumbnail: null,
        parentId: selectedCategory ? selectedCategory.id : null,
        description: "",
      };
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/`,
        newCategory
      );
      refetch();
    }
    if (selectedCategory) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${selectedCategory.id}/series`
      );

      if (!res.data.length) {
        createCategory();
      } else {
        createCategory();
        /*setAdminMessage(
          "Impossibile creare una sottocategoria in una cartella contenente serie!"
        );*/
        /*setTimeout(() => {
          setAdminMessage(null);
        }, 3000);*/
      }
    } else {
      createCategory();
    }
  };

  const updateCategory = async (category) => {
    let thumbnail = null,
      cover = null;
    if (category.upload_thumbnail) {
      thumbnail = await uploadFile(
        category.upload_thumbnail.file,
        `storage/categories/${category.id}`,
        "thumbnail",
        true
      );
      thumbnail = thumbnail.path;
    }
    if (category.upload_cover) {
      cover = await uploadFile(
        category.upload_cover.file,
        `storage/categories/${category.id}`,
        "cover",
        true
      );
      cover = cover.path;
    }
    const { name, position, description, parentId, published } = category;
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/cms/categories/${category.id}`,
      {
        name,
        position,
        description,
        parentId,
        thumbnail,
        cover,
        published,
      }
    );
    if (data.success) {
      setAdminMessage(data.message);
      setTimeout(() => {
        setAdminMessage(null);
      }, 3000);
    }
    refetch();
    onSelectCategory(category);
  };

  const selectCategory = async (id) => {
    if (selectedCategory && id === selectedCategory.id) onSelectCategory(null);
    else {
      onSelectCategory(
        categories.find((cat) => {
          return cat.id === id;
        })
      );
    }
  };

  const deleteCategory = async (cat) => {
    setToDelete(cat.id);
    setToDeleteName(cat.name);
    setModal(true);
  };

  const handleDismiss = () => {
    setToDelete(null);
    setToDeleteName(null);
    setModal(false);
  };

  const handleConfirm = async () => {
    if (toDelete) {
      setModal(false);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${toDelete}`
      );
      selectCategory(null);
      refetch();
    }
  };

  const callRefetch = () => {
    refetch();
  };

  if (isLoading) return <Loader />;

  if (error) return `Error: ${error.message}`;

  return (
    <CategoryContainer>
      <div
        style={{ maxHeight: "50%", overflow: "auto" }}
        onClick={() => {
          if (selectedCategory) selectCategory(selectedCategory.id);
        }}
      >
        <CategoryTitle>Categorie</CategoryTitle>
        <CategoryAdd onClick={() => addCategory()}>Nuova categoria</CategoryAdd>
        <CategoryList
          list={data}
          callRefetch={() => callRefetch()}
          selectedCategory={selectedCategory}
          selectCategory={selectCategory}
        />
      </div>
      <EditCategory
        selectedCategory={selectedCategory}
        updateCategory={updateCategory}
        deleteCategory={deleteCategory}
      />
      <Modal
        hasBackground
        show={showModal}
        handleConfirm={() => handleConfirm()}
        handleDismiss={(val) => handleDismiss(val)}
        heading={`Eliminare "${toDeleteName}"?`}
      >
        La cartella "{toDeleteName}" potrebbe contenere delle serie che verranno
        eliminate, proseguire?
      </Modal>
      <AdminNotification message={adminMessage}></AdminNotification>
    </CategoryContainer>
  );
};

export default Categories;
