import { COLORS, FONTS } from "utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";

/*in client/node_modules/react-mailchimp-subscribe/es/SimpleForm.js modificato "your email" in "email"*/

const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

const Component = styled.div`
    width: 100%;
    height: 160px;
    background: ${COLORS.warning};
    display: flex;
    h3 {
        font-weight: 600;
        line-height: 1.1;
        font-size: 36px;
        color: ${COLORS.white};
        font-style: italic;
        font-family: ${FONTS.h3};
    }
    p{
        margin-top: 11px;
        line-height: 2;
        font-family: ${FONTS.body};
        color: #333;
    }
    .container{
        margin: auto;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .row{
        width: 100%;
        max-width: 1170px;
        margin: auto;
        h3{
            font-family: ${FONTS.h3};
            font-size: 36px;
            font-style: italic;
            font-weight: 600;
        }
        .col-lg-2 {
            margin-top: 22px;
        }    
    }
    
   #mailchimp {
        margin-top: 20px;
        .mc_embed_signup {
            background: transparent;
            button{
                background: ${COLORS.primary} url(https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/form/btn_arrow.svg) 0px 0px no-repeat;                
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                width: 34px;
                text-indent: -99999px;
                display: inline-block;
                height: 36px;
                border-color: transparent;
            }
            input {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                border: none;
                width: 310px;
                font-family: ${FONTS.body};
                font-size: 15px;
                color: ${COLORS.gray};
                background-color: ${COLORS.white};
                box-sizing: border-box;
                height: 35px;
                padding: 0px 0.4em;
                display: inline-block;
                margin: 0;
                vertical-align: top;
            }
            
        }
   }
   @media (max-width: 768px) {
      height: 270px;
      h3{
        text-align: center;
      }
      #mailchimp .mc_embed_signup input {
        width: 200px;
      }
      #mailchimp .mc_embed_signup div{
        text-align: center;
      }
      .row .col-lg-2 {
          margin-top: 0;
      }
     
  }
`;


const PreFooter1 = () => {
    const { t } = useTranslation();
    return (
        <Component>
            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-lg-2">
                            <h3>Newsletter</h3>
                        </div>
                        <div className="col-lg-5 prefooter-description">
                            <p>{t("footer.newsletter")}</p>
                        </div>
                        <div className="col-12 col-xs-12 col-sm-12  col-lg-5">
                            <div id="mailchimp">
                                <MailchimpSubscribe
                                    url={url}
                                    render={({ subscribe, status, message }) => (
                                        <div className="mc_embed_signup">
                                            <SimpleForm onSubmitted={formData => subscribe(formData)} />
                                            {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                                            {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                                            {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Component>
    );
};
export default PreFooter1;
