import styled from "styled-components";

const StyledComponents = styled.h2`
  margin-top: 0;
  color: #00abf0;
  padding: 0 0 0.5rem 1rem;
  font-family: "MontserratBold";
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  border-bottom: 1px solid #7d8598;
  display: flex;
  align-items: center;
`;

const CategoryTitle = ({ children, fontSize }) => {
  return <StyledComponents fontSize={fontSize}>{children}</StyledComponents>;
};

export default CategoryTitle;
