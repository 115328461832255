import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { lighten } from "helpers";
import Modal from "components/common/Modal";
import ArticleName from "./components/ArticleName";
import ArticlePosition from "./components/ArticlePosition";
import ArticleDelete from "./components/ArticleDelete";
import ArticleThumbnail from "./components/ArticleThumbnail";
import ArticleRarity from "./components/ArticleRarity";
import { COLORS, images } from "utils";
import ArticleDetails from "./components/ArticleDetails";

const StyledArticle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #f5f6fb;
  padding: 1rem;
  padding-right: 0;
`;
const StyledFieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;
  position: relative;
  height: 100%;
  flex: ${({ spread }) => (spread ? "1" : "0.3")};
  & label {
    border: none;
    margin-left: 1rem;
    font-size: 0.7rem;
    color: #7d8598;
    flex: 0.3;
    font-family: "MontserratBold";
  }
  & input {
    border-radius: 1rem;
    margin: 0;
    padding: 0.25rem 1rem;
    margin-top: 0.5rem;
    outline: none;
    flex: 1;
    border: 2px solid #f5f6fb;
    font-family: "Montserrat";
    color: #7d8598;
    &:focus {
      border: 2px solid ${lighten("#7d8598", 30)};
    }
    &[type="file"] {
      border: none;
    }
  }
  & textarea {
    border-radius: 1rem;
    margin: 0;
    margin-top: 0.5rem;
    resize: none;
    flex: 0.7;
    outline: none;
    /* min-height: 8rem; */
    height: 100%;
    line-height: 1.5;
    border: 2px solid #f5f6fb;
    padding: 1rem 1.5rem;
    margin-left: 0.5rem;
    font-family: "Montserrat";
    font-size: 0.75rem;
    color: #7d8598;
    line-height: 1.75;
    &:focus {
      border: 2px solid ${lighten("#7d8598", 30)};
    }
  }
  .character-count {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-family: "Montserrat";
    font-size: 0.6rem;
    color: ${COLORS.gray};
  }
`;

const EditArticle = ({
  article,
  deleteArticle,
  emitGallery,
  updateArticle,
  reorderArticles,
  siblings,
}) => {
  const [currentArticle, setArticle] = useState(article);
  const [fileName, setFileName] = useState(null);
  const [thumbnail, setThumbnail] = useState(images.placeholder);
  const [showModal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (article.thumbnail && article.thumbnail !== "null") {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/storage`,
          {
            path: article.thumbnail,
          }
        );
        setThumbnail(null);
        if (res.data) setThumbnail(res.data);
      }
    };
    setArticle(article);
    if (article) fetchThumbnail();
  }, [article]);

  function handleChange(e) {
    const newState = {
      ...currentArticle,
    };
    if (e.target.name === "thumbnail")
      newState[e.target.name] = e.target.files[0];
    else newState[e.target.name] = e.target.value;
    if (e.target.files) setFileName(e.target.files[0].name);
    setArticle(newState);
    updateArticle(newState);
  }
  async function onDeleteSeries(id) {
    setToDelete(id);
    setModal(true);
  }
  function handleDismiss() {
    setToDelete(null);
    setModal(false);
  }
  async function handleConfirm() {
    if (toDelete) {
      setModal(false);
      deleteArticle(toDelete);
    }
  }

  function onChangeOrder(position) {
    const updatedArticle = {
      ...currentArticle,
      position: parseInt(position),
    };
    reorderArticles(updatedArticle);
  }

  function onChangeRarity(rarity) {
    const updatedArticle = {
      ...currentArticle,
      rarity: parseInt(rarity),
    };
    setArticle(updatedArticle);
    updateArticle(updatedArticle);
  }

  function onChangeThumbnail(url) {
    setThumbnail(url);
  }

  return (
    <StyledArticle>
      {!article.isSeparator && (
        <ArticleThumbnail thumbnail={thumbnail} name={currentArticle.name} />
      )}
      <StyledFieldset>
        <ArticleName
          name={currentArticle.name}
          onChange={(e) => handleChange(e)}
        />
        {!article.isSeparator && (
          <ArticleRarity
            fileName={fileName}
            rarity={currentArticle.rarity}
            onChangeRarity={(val) => onChangeRarity(val)}
          />
        )}
      </StyledFieldset>
      <StyledFieldset spread>
        <textarea
          name="description"
          maxLength={article.isSeparator ? 1000 : 150}
          value={currentArticle.description}
          onChange={(e) => handleChange(e)}
        />
        <span className="character-count">
          {currentArticle.description.length}/{article.isSeparator ? 1000 : 150}
        </span>
      </StyledFieldset>
      <ArticlePosition
        position={currentArticle.position}
        siblings={siblings}
        onChangeOrder={(val) => onChangeOrder(val)}
      />
      <ArticleDelete onDeleteSeries={() => onDeleteSeries(article.id)} />
      <Modal
        hasBackground
        show={showModal}
        handleConfirm={() => handleConfirm()}
        handleDismiss={() => handleDismiss()}
        heading={`Eliminare "${article.name}"?`}
      >
        "{article.name}" verrà eliminato definitivamente.
      </Modal>
      {!article.isSeparator && (
        <ArticleDetails
          articleId={article.id}
          emitThumbnail={(url) => onChangeThumbnail(url)}
          emitGallery={emitGallery}
        />
      )}
    </StyledArticle>
  );
};

export default EditArticle;
