import styled, { css } from "styled-components";

const StyledNavbar = styled.nav`
  background-color: #cdd6fd;

  ${({ alignLeft }) =>
    alignLeft &&
    css`
      display: none;
      width: 30%;
      max-width: 200px;
      background-color: #f6f7fb;
      @media screen and (min-width: 767px) {
        display: block;
      }
    `}
`;

const Navbar = ({ alignLeft, children }) => {
  return <StyledNavbar alignLeft={alignLeft}>{children}</StyledNavbar>;
};

export default Navbar;
