import styled from "styled-components";
import { COLORS } from "utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "MontserratBold";
  font-size: 1rem;
  padding: 1rem 0;
  margin: 1rem 0;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  & + div {
    margin-top: 0;
  }
`;

const Title = styled.h3`
  color: ${COLORS.darkGray};
  margin: 0;
  margin-bottom: 1rem;
`;

const Link = styled.span`
  color: ${COLORS.primary};
  cursor: pointer;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  width: fit-content;
  text-decoration: none;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export { Wrapper, Title, Link };
