import styled, { css } from "styled-components";
import { lighten, contrast } from "helpers";

const StyledButton = styled.button`
  width: ${({ size }) => size || "fit-content"};
  padding: 1rem 2rem;
  margin: 0 0.25rem;
  height: ${({ size }) => size || "40px"};
  border: none;
  border-radius: ${({ hasBorderRadius }) => (hasBorderRadius ? "40px" : 0)};
  border: 2px solid transparent;
  background-color: transparent;
  color: #171717;
  box-shadow: ${({ hasShadow }) =>
    hasShadow ? "0 4px 8px 0 rgba(0,0,0,0.2)" : null};
  transition: background-color 0.3s ease;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  z-index: 1;
  /* SMALL */
  ${({ small }) =>
    small &&
    css`
      padding: 0.25rem 1rem;
      height: 1.75rem;
    `}
  /* NO PADDING */
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
  /* ALIGN SELF LEFT */
  ${({ alignLeft }) =>
    alignLeft &&
    css`
      margin-right: auto;
    `}
  /* ALIGN SELF RIGHT */
  ${({ alignRight }) =>
    alignRight &&
    css`
      margin-left: auto;
    `}
  ${({ circle }) =>
    circle &&
    css`
      border-radius: 50%;
    `}
  /* PRIMARY BUTTON */
  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ bgColor }) => bgColor};
      color: ${({ bgColor }) =>
        contrast(bgColor, "#ffffff") > 4.5 ? "#ffffff" : "#171717"};
      &:hover {
        background-color: ${({ bgColor }) =>
          bgColor ? lighten(bgColor, 10) : lighten("#171717", 10)};
      }
      &:active {
        background-color: #ffffff;
      }
    `}
  /* SECONDARY BUTTON */
  ${({ secondary }) =>
    secondary &&
    css`
      color: #171717;
      border-color: ${({ bgColor }) => bgColor};
      overflow: hidden;
      position: relative;
      transition: color 125ms ease 0.125s;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: -1rem;
        bottom: -1rem;
        left: -1rem;
        right: 100%;
        transition: 0.3s ease;
        background-color: ${({ bgColor }) => bgColor};
        z-index: -1;
      }
      &:hover {
        &:before {
          right: -1rem;
        }
        color: ${({ bgColor }) =>
          contrast(bgColor, "#ffffff") > 4.5 ? "#ffffff" : "#171717"};
      }
      &:active {
        transition: border 125ms ease;
        &:before {
          transition: background-color 125ms ease;
          background-color: #ffffff;
        }
        border-color: #ffffff;
      }
    `}
`;

const StyledLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  ${({ small }) =>
    small &&
    css`
      font-size: 0.7rem;
    `}
`;

const Button = ({
  bgColor,
  primary,
  secondary,
  circle,
  info,
  success,
  warning,
  danger,
  transparent,
  hasBorderRadius,
  hasShadow,
  alignLeft,
  alignRight,
  children,
  size,
  onClick,
  noPadding,
  small,
  icon,
}) => {
  let backgroundColor = bgColor;
  if (!bgColor) {
    if (info) backgroundColor = "#31B0D5";
    if (success) backgroundColor = "#449D44";
    if (warning) backgroundColor = "#EC971F";
    if (danger) backgroundColor = "#C9302C";
    if (transparent) backgroundColor = "transparent";
  }

  return (
    <StyledButton
      bgColor={backgroundColor}
      size={size}
      noPadding={noPadding}
      primary={primary}
      secondary={secondary}
      circle={circle}
      hasBorderRadius={hasBorderRadius}
      hasShadow={hasShadow}
      alignLeft={alignLeft}
      alignRight={alignRight}
      small={small}
      onClick={onClick}
      icon={icon}
    >
      {icon && (
        <i
          style={{
            width: "15px",
            height: "15px",
            backgroundImage: `url(${icon})`,
            backgroundSize: "cover",
          }}
        ></i>
      )}
      <StyledLabel small={small}>{children}</StyledLabel>
    </StyledButton>
  );
};

export default Button;
