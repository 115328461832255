import styled, { css } from "styled-components";
import chevron from "assets/icons/chevron-right.png";
import { useEffect, useState } from "react";
import directory from "assets/icons/directory.svg";
import { darken, lighten } from "helpers";

const StyledButton = styled.button`
  background: none;
  border: none;
  outline: none;
  background-image: url(${chevron});
  background-size: cover;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: ${({ depth }) => (depth === 0 ? 0 : "1rem")};
  transition: transform 0.15s ease-out;
  opacity: 0.5;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(90deg);
    `};
`;
const StyledCategory = styled.div`
  padding-left: ${({ depth }) => (depth === 0 ? 0 : "1rem")};
  flex-wrap: wrap;
  cursor: pointer;
  max-height: 0;
  overflow: hidden;
  z-index: ${({ depth }) => depth};
  ${({ isParentOpen }) =>
    isParentOpen &&
    css`
      max-height: 100000000px;
    `};
  > p {
    margin: 0;
    margin-left: ${({ depth }) => -1 * depth + "rem"};
    padding: 0.5rem 0;
    padding-left: ${({ depth }) => depth + 1 + "rem"};
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    position: relative;
    z-index: 1;
    height: 2.5rem;
    > span {
      position: relative;
      line-height: 1;
      display: flex;
      align-items: center;
      color: #7d8598;
      font-size: 0.7rem;
      font-family: "Montserrat";
      &:before {
        content: "";
        display: inline-block;
        height: 16px;
        width: 23px;
        margin: 0 0.5rem;
        background-image: url(${directory});
      }
      &:last-of-type {
        display: flex;
        align-self: center;
        width: 1.75rem;
        height: 1.75rem;
        border: none;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        transition: 0.3s ease;
        margin-left: 1rem;
        margin-left: auto;
        color: ${lighten("#7d8598", 30)};
        font-size: 1.5rem;
        justify-content: center;
        align-items: center;
        line-height: 0;
        margin-right: 1rem;
      }
    }
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      > p {
        background: ${darken("#f5f6fb", 2)};
        > span {
          font-family: "MontserratBold";
          &:last-of-type {
            font-family: "Montserrat";
          }
        }
      }
    `}
`;

const CategoryListItem = ({
  depth = 0,
  category,
  selectCategory,
  selectedCategory,
  handleDrag,
  handleDrop,
  isParentOpen = true,
  updateBatchCategories,
  baseColor = "#eaeaea",
}) => {
  const [draggable, setDraggable] = useState(false);
  const [dragId, setDragId] = useState();
  const [orderedItems, setItems] = useState(category.children);
  const [isOpen, setOpen] = useState(false);
  const { id, name } = category;
  const primaryColor = baseColor === "#ffffff" ? "#eaeaea" : "#ffffff";
  const isSelected = selectedCategory && selectedCategory.id === id;

  useEffect(() => {
    setItems(category.children);
  }, [category]);

  const onHandleDrag = async (ev) => {
    if (!dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      setDragId(target.id);
    }
  };
  const onHandleDrop = async (ev) => {
    if (dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      const dragBox = orderedItems.find((box) => box.id === parseInt(dragId));
      const dropBox = orderedItems.find(
        (box) => box.id === parseInt(target.id)
      );

      if (dragBox && dropBox && dragBox.parentId === dropBox.parentId) {
        const dragBoxIndex = orderedItems.indexOf(dragBox);
        const dropBoxIndex = orderedItems.indexOf(dropBox);

        const newBoxState = [...orderedItems];
        let cutOut = newBoxState.splice(dragBoxIndex, 1)[0];
        newBoxState.splice(dropBoxIndex, 0, cutOut);
        newBoxState.map((item, index) => (item.position = index));
        setItems(newBoxState);
        updateBatchCategories(newBoxState);
      }
      setDragId(null);
    }
  };

  return (
    <StyledCategory
      depth={depth}
      primaryColor={primaryColor}
      isParentOpen={isParentOpen}
      isSelected={isSelected}
      id={category.id}
      draggable={draggable}
      style={{ userSelect: "none" }}
      onDragOver={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setDraggable(false);
      }}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      onClick={(e) => e.stopPropagation()}
    >
      <p
        onClick={() => selectCategory(id)}
        onMouseDown={(e) => {
          setDraggable(true);
        }}
      >
        {category.children.length > 0 && (
          <StyledButton
            depth={depth}
            open={isOpen}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!isOpen);
            }}
          ></StyledButton>
        )}
        <span>{name}</span>
        <span>&#10021;</span>
      </p>
      <div onClick={(e) => e.stopPropagation()}>
        {orderedItems.length > 0
          ? orderedItems
              .sort((a, b) => a.position - b.position)
              .map((child, index) => {
                const baseColor = index % 2 === 0 ? "#ffffff" : "#eaeaea";
                return (
                  <CategoryListItem
                    key={child.id}
                    depth={depth + 1}
                    category={child}
                    selectCategory={selectCategory}
                    baseColor={baseColor}
                    isParentOpen={isOpen}
                    handleDrag={onHandleDrag}
                    handleDrop={onHandleDrop}
                    updateBatchCategories={updateBatchCategories}
                    selectedCategory={selectedCategory}
                  />
                );
              })
          : null}
      </div>
    </StyledCategory>
  );
};

export default CategoryListItem;
