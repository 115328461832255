import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Container } from "components";
import ArticleList from "./series/ArticleList";
import Loader from "components/common/Loader/Loader";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "library/public/common";
import { Banner, Description, ListIndex } from "library/public/pages/archive";

const Series = () => {
  const { series } = useParams();

  const API_URL = `${
    process.env.REACT_APP_API_URL
  }/api/cms/series/slug/${series.replaceAll("_", " ")}`;

  async function fetchSeries() {
    return await fetch(API_URL)
      .then((res) => res.json())
      .then((data) => data[0]);
  }

  async function fetchArticles() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/api/cms/series/${currentSeries.id}/articles`
    )
      .then((res) => res.json())
      .then((data) => data);
  }

  const {
    isLoading,
    error,
    data: currentSeries,
  } = useQuery("categories", () => fetchSeries());

  const {
    isLoading: articlesLoading,
    error: articleError,
    data: articles,
  } = useQuery("articles", () => fetchArticles(), {
    enabled: !!currentSeries,
  });

  if (isLoading || articlesLoading) return <Loader />;
  if (error || articleError) return `Error: ${error.message}`;

  const microdata = {
    "@context": "https://schema.org/",
    "@type": "Thing",
    name: currentSeries.name,
    description: currentSeries.shortDescription,
    url: window.location.href,
  };

  return (
    <>
      <Helmet>
        <title>{currentSeries.name} | Gian&Davi Collezioni</title>
        <script type="application/ld+json">{JSON.stringify(microdata)}</script>
      </Helmet>
      <Breadcrumbs currentId={currentSeries.categoryId} />
      <Banner
        title={currentSeries.name}
        description={currentSeries.shortDescription}
        image={currentSeries.thumbnail}
        item={currentSeries}
      />
      <Container flexDirection="column" noPadding>
        <Description content={currentSeries.longDescription} />
        <ListIndex anchors={articles.filter((item) => item.isSeparator)} />
        <ArticleList list={articles} />
      </Container>
    </>
  );
};

export default Series;
