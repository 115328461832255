import Modal from "components/common/Modal";
import { useState } from "react";
import styled, { css } from "styled-components";
import { lighten } from "helpers";

const StyledComponent = styled.div`
  color: #00abf0;
  cursor: pointer;
  font-family: "Montserrat";
  margin: 0;
  padding: 0.5rem 1rem;
  padding-left: 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 0;
  font-size: 0.7rem;
  & > span {
    display: flex;
    align-self: center;
    width: 1.75rem;
    height: 1.75rem;
    border: none;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease;
    margin-left: 1rem;
    color: ${lighten("#7d8598", 30)};
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    line-height: 0;
    margin-right: 0.25rem;
    font-size: 1.5rem;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #ffffff};
      font-family: "MontserratBold";
      & > span {
        font-family: "Montserrat";
      }
    `};
`;
const StyledDelete = styled.div`
  margin-left: auto;
  width: 20px;
  height: 20px;
  transition: opacity 0.3s ease;
  background: red;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "MontserratBold";
  font-size: 1.5rem;
  line-height: 0;
  padding-bottom: 0.125rem;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.3;
  }
`;

const SeriesListItem = ({
  callback,
  isSelected,
  deleteSeries,
  handleDrag,
  handleDrop,
  series: { id, name },
}) => {
  const [showModal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const onDeleteSeries = async (id) => {
    setToDelete(id);
    setModal(true);
  };

  const handleDismiss = () => {
    setToDelete(null);
    setModal(false);
  };

  const handleConfirm = async () => {
    if (toDelete) {
      setModal(false);
      deleteSeries(id);
    }
  };

  return (
    <StyledComponent
      isSelected={isSelected}
      onClick={callback}
      id={id}
      draggable={true}
      onDragOver={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      }}
      onDragStart={handleDrag}
      onDrop={handleDrop}
    >
      <span>&#10021;</span>
      {name}
      <StyledDelete
        onClick={(e) => {
          e.stopPropagation();
          onDeleteSeries(id);
        }}
      >
        &times;
      </StyledDelete>
      <Modal
        hasBackground
        show={showModal}
        handleConfirm={() => handleConfirm()}
        handleDismiss={() => handleDismiss()}
        heading={`Eliminare "${name}"?`}
      >
        "{name}" verrà eliminato definitivamente.
      </Modal>
    </StyledComponent>
  );
};

export default SeriesListItem;
