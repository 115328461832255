import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "./Search.styled";

const Search = () => {
  const { t } = useTranslation();
  
  const [search,setSearch] = useState("");
  const url = "https://gianedavicollezioni.com/catalogsearch/result/?q="+search;
  const handleSubmit = (e) => {
    e.preventDefault();
    alert(search);
  }


  return (
      <SearchBar onSubmit={handleSubmit}>
          <label>
            <input
                type="text"
                value={search}
                onChange={(e) => {setSearch(e.target.value)}}
                placeholder={t("search.action")}
            />
          </label>
          <a href={url}><button type="submit" /></a>
      </SearchBar>
  );
};

export default Search;
