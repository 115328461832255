// DEPENDENCIES
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
// COMPONENTS
import { Loader } from "components/common";
import {FONTS, images} from "utils";
import Rarity from "./ArticleListItem/Rarity";
import Thumbnail from "./ArticleListItem/Thumbnail";

const StyledArticle = styled.div`
  display: flex;
  margin-bottom: 3rem;
  text-decoration: none;
  flex: 0 0 50%;
  padding: 0 1rem;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;
const StyledInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.body};
  color: #7d8598;
  margin-left: 1rem;
  overflow: hidden;
  h2 {
    margin: 0;
    font-family: ${FONTS.h2}
    font-size: 1.25rem;
  }
  p {
    margin: 1rem 0 0;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    line-height: 1.25rem;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    h2 {
      margin-top: 1rem;
    }
  }
`;

const ArticleListItem = ({ article: { id, name, description, rarity } }) => {
  const [gallery, setGallery] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    function fetchGallery() {
      setImageLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/cms/articles/${id}/gallery`)
        .then((res) => {
          const state = [];
          for (const item in res.data) {
            state.push(res.data[item]);
          }
          setGallery(state);
        })
        .then(() => setImageLoading(false))
        .catch(() => {
          setGallery([{ url: images.placeholder }]);
          setImageLoading(false);
        });
    }
    fetchGallery();
  }, [id]);

  return (
    <StyledArticle href="/">
      {imageLoading ? (
        <div
          style={{
            width: "7vw",
            height: "7vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Thumbnail gallery={gallery} />
      )}
      <StyledInfo>
        <h2>{name}</h2>
        <p>{description}</p>
        <Rarity rarity={rarity} />
      </StyledInfo>
    </StyledArticle>
  );
};

export default ArticleListItem;
