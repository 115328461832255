import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  min-height: 100%;
  padding-bottom: 3rem;
`;

const CategoryContainer = ({ children }) => {
  return <StyledComponent>{children}</StyledComponent>;
};
export default CategoryContainer;
