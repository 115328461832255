import styled, { css } from "styled-components";

const StyledNavbarList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ alignVertical }) =>
    alignVertical &&
    css`
      flex-direction: column;
    `}
`;

const NavbarList = ({ alignVertical, children }) => {
  return (
    <StyledNavbarList alignVertical={alignVertical}>
      {children}
    </StyledNavbarList>
  );
};

export default NavbarList;
