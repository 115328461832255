import { darken } from "helpers";
import styled from "styled-components";

const StyledComponent = styled.p`
  width: 90%;
  margin: 1rem auto 0;
  background-color: ${darken("#f5f6fb", 5)};
  padding: 1rem;
  color: #7d8598;
  font-family: "Montserrat";
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SeriesMessage = ({ children }) => {
  return <StyledComponent>{children}</StyledComponent>;
};

export default SeriesMessage;
