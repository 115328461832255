import Alert from "components/common/Alert";
import { COLORS, icons } from "utils";
import { useState } from "react";
import styled from "styled-components";
import { darken } from "helpers";

const MainTable = styled.table`
  width: 100%;
  max-width: 100%;
  color: ${COLORS.darkGray};
  border-collapse: collapse;
  thead {
    tr {
      align-items: center;
      background: ${darken(COLORS.lightGray, 5)};
      font-family: "MontserratBold";
      font-size: 0.8rem;
      td {
        padding: 1rem;
        position: relative;
        cursor: pointer;
        &:after {
          background: none;
          background-image: url(${icons.arrowPrev});
          background-position: center;
          content: "";
          display: none;
          position: absolute;
          top: 50%;
          margin-top: -0.5rem;
          right: 1rem;
          height: 1rem;
          transform: rotate(-90deg);
          width: 1rem;
        }
        &.ASC {
          &:after {
            transform: rotate(-90deg);
            display: inline-block;
          }
        }
        &.DISC {
          &:after {
            transform: rotate(90deg);
            display: inline-block;
          }
        }
      }
    }
  }
  tbody {
    tr {
      align-items: center;
      background: ${darken(COLORS.lightGray, 2)};
      font-family: "Montserrat";
      font-size: 0.8rem;
      &:nth-of-type(odd) {
        background: ${darken(COLORS.lightGray, 3)};
      }
      td {
        padding: 1rem;
      }
    }
  }
`;

const Table = ({ data, headers }) => {
  const [sorting, setSorting] = useState({
    prop: null,
    dir: null,
  });

  function renderColumn(column, enableSorting = false, isDate = false) {
    if (enableSorting)
      return (
        <td
          key={column}
          className={sorting.prop === column ? sorting.dir : null}
          onClick={() => {
            if (data.length > 1) {
              if (sorting.prop === column) {
                if (sorting.dir === "ASC") {
                  setSorting({
                    prop: column,
                    dir: "DISC",
                  });
                } else {
                  setSorting({
                    prop: column,
                    dir: "ASC",
                  });
                }
              } else
                setSorting({
                  prop: column,
                  dir: "ASC",
                });
            }
          }}
        >
          {column}
        </td>
      );
    return (
      <td key={column}>
        {isDate ? new Date(column).toLocaleDateString() : column}
      </td>
    );
  }

  function renderRow(item, index) {
    const cols = [];
    for (const p in item) {
      cols.push({ label: p, data: item[p] });
    }
    return (
      <tr key={index}>
        {cols.map((col) =>
          renderColumn(
            col.data,
            false,
            col.label === "created_at" || col.label === "updated_at"
          )
        )}
      </tr>
    );
  }

  function renderHeaders() {
    const cols = [];
    if (headers) {
      for (const p of headers) {
        cols.push(p);
      }
    } else {
      for (const p in data[0]) {
        cols.push(p);
      }
    }
    return <tr>{cols.map((col) => renderColumn(col, true))}</tr>;
  }

  if (!data.length)
    return (
      <Alert type="info" message="Ops, sembra non ci sia nulla al momento!" />
    );

  return (
    <MainTable>
      <thead>{renderHeaders()}</thead>
      <tbody>
        {data
          .sort((a, b) => {
            if (a[sorting.prop] < b[sorting.prop]) {
              if (sorting.dir === "ASC") return -1;
              return 1;
            }
            if (a[sorting.prop] > b[sorting.prop]) {
              if (sorting.dir === "ASC") return 1;
              return -1;
            }
            return 0;
          })
          .map((item, index) => renderRow(item, index))}
      </tbody>
    </MainTable>
  );
};

export default Table;
