// DEPENDENCIES
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// COMPONENTS
import { Container, Link, Divider } from "./Breadcrumbs.styled";

const Breadcrumbs = ({ currentId }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  let currentPath = location.pathname.split("/");
  currentPath = currentPath[currentPath.length - 1];

  useEffect(() => {
    async function fetchBreadcrumbs(id) {
      if (id) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/cms/categories/${id}`)
          .then((res) => {
            if (res.data)
              setBreadcrumbs((p) => [
                ...p,
                res.data.name.replaceAll(" - ", "_-_").replaceAll(" ", "_"),
              ]);
            return res.data;
          })
          .then((data) => fetchBreadcrumbs(data.parentId));
      }
    }
    if (currentId) fetchBreadcrumbs(currentId);
  }, [currentId]);

  return (
    <Container>
      <Link href="/" title="Vai alla home">
        Home
      </Link>
      <Divider>/</Divider>
      <Link href="/categorie" title="Vai all'archivio'">
        Archivio
      </Link>
      <Divider>/</Divider>
      {breadcrumbs
        .map((breadcrumb) => {
          if (
            breadcrumb.replaceAll("_", " ") !== currentPath.replaceAll("_", " ")
          )
            return (
              <Fragment key={breadcrumb}>
                <Link
                  href={`/categorie/${breadcrumb}`}
                  title={`Vai a ${breadcrumb.replaceAll("_", " ")}`}
                >
                  {breadcrumb.replaceAll("_", " ")}
                </Link>
                <Divider>/</Divider>
              </Fragment>
            );
          return null;
        })
        .reverse()}
      <Divider>/</Divider>
      <Link currentPath>{currentPath.replaceAll("_", " ")}</Link>
    </Container>
  );
};

export default Breadcrumbs;
