import styled from "styled-components";
import { COLORS, FONTS } from "utils";

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  text-decoration: none;
  @media (min-width: 768px) {
    flex: 0 0 50%;
  }
  @media (min-width: 1024px) {
    flex: 0 0 25%;
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 0;
  margin: 1rem;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    transition: transform 0.6s ease-out;
    font-size: 0;
    &.loading {
      opacity: 0;
    }
  }
  &:hover {
    img {
      transform: scale3d(1.125, 1.125, 1.125);
    }
  }
`;

const Title = styled.h4`
  padding: 0 1rem;
  text-transform: uppercase;
  font-family: ${FONTS.h3};
  font-size: 1rem;
  color: #7d8598;
  margin: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
  cursor: default;
`;

export { Wrapper, Thumbnail, Title };
