import Navbar from "./Navbar";
import Search from "./Search";
import Topbar from "./Topbar";
import Container from "./Header.styled";
import TopPanel from "./TopPanel";

const Header = () => {
  return (
    <Container>
      <TopPanel />
      <Topbar />
      <Search />
      <Navbar />
    </Container>
  );
};

export default Header;
