import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledNavbarLink = styled.li`
  padding: 0;
  margin: 0;
  & a {
    text-decoration: none;
    color: #171717;
    padding: 1rem;
    display: block;
    font-weight: 600;
  }
`;

const NavbarLink = ({ to, external, children }) => {
  return (
    <StyledNavbarLink>
      <Link to={to || "/"} target={external ? "_blank" : null} rel="noreferrer">
        {children}
      </Link>
    </StyledNavbarLink>
  );
};

export default NavbarLink;
