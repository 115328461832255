import styled from "styled-components";

import { COLORS, svg } from "utils";

const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  white-space: nowrap;
  div {
    color: ${COLORS.primary};
    font-family: "Montserrat";
    position: relative;
  }
  @media (max-width: 768px) {
    padding: 1rem;
    overflow: auto;
    span {
      margin: 0 10px;
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${({ currentPath }) =>
    currentPath ? COLORS.primary : COLORS.darkGray};
  font-family: ${({ currentPath }) =>
    currentPath ? "MontserratBold" : "Montserrat"};
  position: relative;
  &:first-of-type {
    background: transparent;
    background-image: url(${svg.Home});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    height: 1rem;
    transition: opacity 0.3s ease;
    width: 1rem;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const Divider = styled.span`
  content: "/";
  margin: 0 1rem;
  user-select: none;
  pointer-events: none;
  cursor: default;
  z-index: 2;
  color: ${COLORS.darkGray};
  &:last-of-type {
    display: none;
  }
  @media (max-width: 768px) {
    margin: 0 10px;
  }
`;

export { Container, Link, Divider };
