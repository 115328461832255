import { Container } from "components";
import { useAuthState } from "context/auth";
import { COLORS } from "utils";
import styled from "styled-components";
import AdminUsers from "./Users";

const WelcomeMessage = styled.h1`
  color: ${COLORS.primary};
  font-family: "MontserratBold";
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 2rem;
`;

const Dashboard = () => {
  const { userDetails } = useAuthState();

  return (
    <Container flexDirection="column">
      {userDetails && (
        <WelcomeMessage>Bentornato, {userDetails.firstName}!</WelcomeMessage>
      )}
      <AdminUsers />
    </Container>
  );
};

export default Dashboard;
