import { COLORS } from "utils";
import styled from "styled-components";
import { darken, lighten } from "helpers";

const StyledComponent = styled.div`
  position: fixed;
  inset: 0;
  z-index: 100;
  background: ${({ hasBackground }) =>
    hasBackground ? "rgba(0, 0, 0, 0.7)" : "transparent"};
`;

const StyledHeading = styled.p`
  font-family: "MontserratBold";
  font-size: 1rem;
  color: #7d8598;
  margin: 0 0 1rem;
  margin-top: auto;
`;

const StyledContent = styled.p`
  font-family: "Montserrat";
  font-size: 1rem;
  text-align: left;
  color: #7d8598;
  margin: 0.5rem 0 0;
`;

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  padding: 1rem 10% 2rem;
  font-size: 1.25rem;
  font-family: "Montserrat";
  user-select: none;
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const StyledClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 0;
  text-align: center;
  border-radius: 50%;
  line-height: 3rem;
  vertical-align: middle;
  font-size: 2.5rem;
  font-family: "MontserratBold";
  color: #7d8598;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    color: ${lighten("7d8598", 10)};
  }
`;

const StyledActions = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    width: fit-content;
    margin: 0 1rem;
    font-family: "MontserratBold";
    padding: 0.25rem 1rem;
    border-radius: 2rem;
    border: none;
    outline: none;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
  }
`;

const StyledDismiss = styled.button`
  background: ${() => COLORS.gray};
  &:hover {
    background: ${() => darken(COLORS.gray, 10)};
  }
`;

const StyledConfirm = styled.button`
  background: ${() => COLORS.error};
  &:hover {
    background: ${() => darken(COLORS.error, 10)};
  }
`;

const Modal = ({
  hasBackground,
  show,
  heading,
  children,
  handleConfirm,
  handleDismiss,
}) => {
  const onDismiss = (e) => {
    handleDismiss();
  };

  const onConfirm = () => {
    handleConfirm();
  };

  if (!show) return null;

  return (
    <StyledComponent
      hasBackground={hasBackground}
      onClick={(e) => onDismiss(e)}
    >
      <StyledContainer onClick={(e) => e.stopPropagation()}>
        <StyledClose onClick={(e) => onDismiss(e)}>&times;</StyledClose>
        <StyledHeading>{heading}</StyledHeading>
        <StyledContent>{children}</StyledContent>
        <StyledActions>
          <StyledDismiss onClick={(e) => onDismiss(e)}>Annulla</StyledDismiss>
          <StyledConfirm onClick={onConfirm}>Conferma</StyledConfirm>
        </StyledActions>
      </StyledContainer>
    </StyledComponent>
  );
};

export default Modal;
