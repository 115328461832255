import { Link } from "react-router-dom";
import { COLORS } from "utils";

const NotFound = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingTop: "3rem",
      }}
    >
      <h1
        style={{
          color: COLORS.primary,
        }}
      >
        Oops! Pagina non trovata!
      </h1>
      <p
        style={{
          color: COLORS.darkGray,
          width: "100%",
          margin: "1rem auto",
          maxWidth: "400px",
        }}
      >
        A quanto pare la pagina che stavi cercando non esiste oppure è stata
        spostata.
      </p>
      <Link
        to="/"
        style={{
          color: COLORS.primary,
          textDecoration: "none",
        }}
      >
        Torna alla home
      </Link>
    </div>
  );
};

export default NotFound;
