import { createGlobalStyle } from "styled-components";

import Montserrat from "assets/fonts/Montserrat-Regular.otf";
import MontserratBold from "assets/fonts/Montserrat-Bold.otf";
import AderirekSans from "assets/fonts/Adirek_Sans_ExtraBold_Italic.otf";

import SFPRODisplayBold from "assets/fonts/SF-Pro-Display-Bold.otf";
import SFPRODisplayRegular from "assets/fonts/SF-Pro-Display-Regular.otf";

const Fonts = createGlobalStyle`

    @font-face {
        font-family: "Montserrat";
        src: url(${Montserrat});
    }
    @font-face {
        font-family: "MontserratBold";
        src: url(${MontserratBold});
    }
    @font-face {
        font-family: 'AderirekSans';
        src: url(${AderirekSans});
    } 
    @font-face {
        font-family: "SFPRODisplayBold";
        src: url(${SFPRODisplayBold});
    }
    @font-face {
        font-family: "SFPRODisplayRegular";
        src: url(${SFPRODisplayRegular});
    }
`;

export default Fonts;
