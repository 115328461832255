import { useState } from "react";
import styled from "styled-components";
import { COLORS, icons } from "utils";
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

const StyledThumbnail = styled.div`
  flex: 0 0 40%;
  max-width: 400px;
  > div {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      transition: transform 0.6s ease-out;
      font-size: 0;
      &.loading {
        opacity: 0;
      }
    }
  }
`;
const MoveLeft = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 3;
  background: ${COLORS.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  cursor: pointer;
  opacity: 0.3;
  &:after {
    display: block;
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(${icons.arrowPrev});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0;
  }
`;
const MoveRight = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  background: ${COLORS.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  cursor: pointer;
  opacity: 0.3;
  &:after {
    display: block;
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(${icons.arrowNext});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0;
  }
`;
const Thumbnail = ({ gallery }) => {
  const [activeImg, setActiveImg] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [open, setOpen] = useState(false)
  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }
  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }
  function handleTouchEnd() {
    if (touchStart - touchEnd > 150) {
      const idx = activeImg + 1;
      if (idx < gallery.length) setActiveImg(idx);
    }
    if (touchStart - touchEnd < -150) {
      const idx = activeImg - 1;
      if (idx >= 0) setActiveImg(idx);
    }
  }

  if (!gallery || !gallery.length) return null;

  return (
    <StyledThumbnail
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
    >
      <div>
        {activeImg > 0 && (
          <MoveLeft
            onClick={() => {
              const idx = activeImg - 1;
              setActiveImg(idx);
            }}
          />
        )}
        <img src={gallery[activeImg].url} alt="" onClick={() => setOpen(true)}/>
        {open && (
        <Lightbox
            mainSrc={gallery[activeImg].url}
            nextSrc={gallery[(activeImg + 1 )% gallery.length].url}
            prevSrc={gallery[(activeImg + gallery.length -1) % gallery.length].url}
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() => {
              setActiveImg((activeImg + gallery.length -1) % gallery.length);
              }
            }
            onMoveNextRequest={() => {
              setActiveImg((activeImg + 1 )% gallery.length);
              }
            }
        />
        )}
        {activeImg < gallery.length - 1 && (
          <MoveRight
            onClick={() => {
              const idx = activeImg + 1;
              setActiveImg(idx);
            }}
          />
        )}
      </div>
    </StyledThumbnail>
  );
};

export default Thumbnail;
