// DEPENDENCIES
import styled from "styled-components";
// UTILS
import { darken } from "helpers";
// ASSETS
import placeholder from "assets/img/placeholder_immagine.png";

const Fieldset = styled.fieldset`
  position: relative;
  width: 6rem;
  text-align: center;
  border: none;
  > div {
    width: 6rem;
    height: 6rem;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    & img {
      width: 100%;
      height: auto;
      transition: 0.3s ease;
    }
  }
  span {
    display: inline-block;
    margin: 0.75rem auto 0;
    color: #7d8598;
    font-family: "Montserrat";
    font-size: 0.7rem;
    background-color: #f5f6fb;
    padding: 0.125rem 1rem;
    border-radius: 1rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: ${darken("#f5f6fb", 5)};
    }
    &:active {
      opacity: 0.2;
    }
  }
`;

const ArticleThumbnail = ({ thumbnail, alt }) => {
  return (
    <Fieldset>
      <div>
        <img
          src={thumbnail || placeholder}
          alt={alt}
          width={100}
          height={100}
        />
      </div>
    </Fieldset>
  );
};

export default ArticleThumbnail;
