import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthDispatch, logout, useAuthState } from "context/auth";
import {
  Container,
  Logo,
  Menu,
  MobileMenu,
  Actions,
  Account,
  Cart,
  Link,
} from "./Topbar.styled";
import logo from "assets/img/logo.svg";

const HeaderTop = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { userDetails } = useAuthState();
  const dispatch = useAuthDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogout = () => {
    logout(dispatch);
    history.go("/");
  };

  function renderMobileMenu() {
    return (
      <MobileMenu className={isMenuOpen ? "open" : null}>
        <div>
          <ul>
            <li>
              <a href="/">{t("menu.profile")}</a>
            </li>
            <li>
              <a href="/">{t("menu.cart")}</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="/">{t("menu.catalog")}</a>
            </li>
            <li>
              <a href="/">{t("menu.blog")}</a>
            </li>
            <li>
              <a href="/">{t("menu.archive")}</a>
            </li>
          </ul>
          <button>{t("system.logout")}</button>
        </div>
      </MobileMenu>
    );
  }

  function renderUserActions() {
    /*if (!userDetails) return <Link href="/login">{t("system.login")}</Link>;*/
    return (
      <>
        {userDetails.role !== "user" && <Account href="https://gianedavicollezioni.com/customer/account/login/" />}
        <Cart href="https://gianedavicollezioni.com/checkout/cart/" />
        {/*<Link onClick={handleLogout}>{t("system.logout")}</Link>*/}
      </>
    );
  }

  return (
    <Container>
      <Menu
        className={isMenuOpen ? "active" : null}
        onClick={() => setMenuOpen(!isMenuOpen)}
      >
        <div></div>
        <div></div>
        <div></div>
      </Menu>
      <Logo href="/">
        <img src={logo} alt="Gian&Davi Collezioni" />
      </Logo>
      <Actions>{renderUserActions()}</Actions>
      {renderMobileMenu()}
    </Container>
  );
};

export default HeaderTop;
