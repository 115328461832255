// DEPENDENCIES
import styled from "styled-components";
// UTILS
import { COLORS } from "utils";

const Fieldset = styled.fieldset`
  align-items: center;
  align-self: center;
  border: none;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
`;
const Select = styled.select`
  border: 1px solid ${COLORS.gray};
  color: ${COLORS.darkGray};
  outline: none;
  &:focus {
    border-color: ${COLORS.darkGray};
  }
`;

const ArticlePosition = ({ position, siblings, onChangeOrder }) => {
  function renderOptions() {
    let options = [];
    for (let i = 0; i < siblings; i++) {
      options.push(i);
    }
    return options.map((i) => (
      <option value={i} key={i}>
        {i}
      </option>
    ));
  }

  function handleChange(e) {
    onChangeOrder(e.target.value);
  }

  return (
    <Fieldset>
      <Select value={position} onChange={(e) => handleChange(e)}>
        {renderOptions()}
      </Select>
    </Fieldset>
  );
};

export default ArticlePosition;
