import React from "react";
import styled, { keyframes } from "styled-components";

const load = keyframes` 
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  font-size: 10px;
  margin: 10rem auto;
  text-indent: -9999em;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: linear-gradient(to right, #05abf0 10%, rgba(5, 171, 240, 0) 42%);
  position: relative;
  animation: ${load} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: #05abf0;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  &:after {
    background: #05abf0;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Loader = () => {
  return <StyledLoader />;
};

export default Loader;
