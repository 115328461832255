import styled from "styled-components";

const Component = styled.div`
    padding: 0 24%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00C3FF;
    height: 90px;
    img {
        max-width: 180px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .row{
        width: 100%;
    }
    @media (max-width: 768px) {
      height: 300px;
  }
`;


const PreFooter3 = () => {
    return (
        <Component>
            <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-lg-3">
                    <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/loghi_pagamenti/Paypal.svg"
                         alt="PayPal"/>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-lg-3">
                    <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/loghi_pagamenti/postepay.svg"
                         alt="Postepay"/>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-lg-3">
                    <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/loghi_pagamenti/bank_transfer.svg"
                         alt="Bank Transfer"  style={{ maxWidth: 250 }}/>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-lg-3">
                    <img src="https://gianedavicollezioni.com/static/frontend/Takorabo/nemo2/it_IT/images/footer/loghi_pagamenti/satispay.svg"
                         alt="Satispay"/>
                </div>
            </div>
        </Component>
    );
};
export default PreFooter3;