import { Navbar, NavbarList } from "components";
import logo from "assets/img/logo.svg";
import { Link } from "react-router-dom";

import styled from "styled-components";

import {
  archive,
  archiveHover,
  blog,
  blogHover,
  dashboard,
  dashboardHover,
  magento,
  magentoHover,
  orders,
  ordersHover,
} from "assets/svg";
import React from "react";

const MainWrapper = styled.main`
  width: ${({ width }) => width || "100%"};
  min-height: 100%;
  padding-top: 1.35rem;
  > div {
    max-width: 100%;
  }
`;

const StyledLink = styled.li`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  & a {
    text-decoration: none;
    color: #7d8598;
    padding: 1rem;
    padding-top: 0;
    display: block;
    font-weight: 600;
    text-align: center;
    & div {
      width: 8rem;
      height: 8rem;
      margin: 0 auto;
      background-image: ${({ icon }) => `url(${icon})`};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      transition: 0.3s ease;
      position: relative;
      & span {
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  &:hover {
    & a {
      & div {
        background-image: ${({ iconHover }) => `url(${iconHover})`};
      }
    }
  }
`;

const CmsLayout = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        height: "100%",
        backgroundColor: "#f6f7fb",
      }}
    >
      <Navbar alignLeft>
        <header
          style={{
            width: "100%",
            background: "#f6f7fb",
            padding: "1rem 0",
          }}
        >
          <Link
            to="/"
            style={{
              width: "100%",
              display: "block",
              maxWidth: "200px",
              padding: "1rem",
              position: "relative",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={logo}
              alt="Gian&Davi Collezioni"
            />
          </Link>
        </header>
        <NavbarList alignVertical>
          <StyledLink
            icon={dashboard.default}
            iconHover={dashboardHover.default}
          >
            <Link to="/admin/dashboard">
              <div>
                <span>Dashboard</span>
              </div>
            </Link>
          </StyledLink>
          <StyledLink icon={archive.default} iconHover={archiveHover.default}>
            <Link to="/admin/archivio">
              <div>
                <span>Archivio</span>
              </div>
            </Link>
          </StyledLink>
          <StyledLink icon={blog.default} iconHover={blogHover.default}>
            <Link to="/admin/blog">
              <div>
                <span>Redazione</span>
              </div>
            </Link>
          </StyledLink>
          <StyledLink icon={orders.default} iconHover={ordersHover.default}>
            <Link to="/admin/ordini">
              <div>
                <span>Ordini</span>
              </div>
            </Link>
          </StyledLink>
          <StyledLink icon={magento.default} iconHover={magentoHover.default}>
            <a href="/magento/index.php">
              <div>
                <span>Magento</span>
              </div>
            </a>
          </StyledLink>
        </NavbarList>
      </Navbar>
      <MainWrapper>{children}</MainWrapper>
    </div>
  );
};

export default CmsLayout;
