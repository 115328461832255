// DEPENDENCIES
import styled from "styled-components";

const DeleteBtn = styled.div`
  margin-left: 1rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  background: red;
  border: none;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: "MontserratBold";
  font-size: 1.5rem;
  line-height: 0;
  padding-bottom: 0.125rem;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.3;
  }
`;

const ArticleDelete = ({ onDeleteSeries }) => {
  return (
    <DeleteBtn
      onClick={(e) => {
        e.stopPropagation();
        onDeleteSeries();
      }}
    >
      &times;
    </DeleteBtn>
  );
};

export default ArticleDelete;
