import styled from "styled-components";
import { COLORS } from "utils";

const Wrapper = styled.header`
  padding: 3rem 1rem;
  background: ${COLORS.primary};
  color: ${COLORS.white};
  width: 100%;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Row = styled.div`
  align-items: baseline;
  justify-content: flex-start;
  display: flex;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-family: "MontserratBold";
  margin: 0;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Description = styled.p`
  font-family: "Montserrat";
  font-size: 0.9rem;
  line-height: 1.25rem;
  margin: 0;
  margin-left: auto;
  margin-top: 1rem;
  max-width: 50%;
  white-space: pre-wrap;
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-size: 0.9rem;
  line-height: 1.25rem;
  padding-left: 7rem;
  margin: 0;
  margin-top: 1rem;
  @media (max-width: 768px) {
    padding: 0;
  }
  span {
    display: block;
  }
`;

export { Wrapper, Row, Title, Description, Paragraph };
