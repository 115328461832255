// DEPENDENCIES
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
// COMPONENTS
import Loader from "components/common/Loader/Loader";
import { Container } from "components";
import { Description, List, ListItem } from "library/public/pages/archive";

const description = `Esperti in collezionismo da oltre 50 anni, 
abbiamo deciso di mettere a disposizione il nostro archivio,
per permettere a tutti i collezionisti di scoprire Rarità e pezzi più unici che rari.
Immergetevi nell’archivio delle Sorpresine Kinder, delle gommine del Mulino Bianco o dei Puffi in PVC,
catalogati per serie in maniera scrupolosa e con tante curiosità.`;

const Categories = () => {
  const { isLoading, error, data } = useQuery("categories", () =>
    fetch(`${process.env.REACT_APP_API_URL}/api/cms/categories/active`).then((res) =>
      res.json()
    )
  );

  if (isLoading) return <Loader />;
  if (error) return `Error: ${error.message}`;

  function renderListItem() {
    return data
      .filter((cat) => cat.parentId === null)
      .sort((a, b) => a.position - b.position)
      .map((cat) => (
        <ListItem item={cat} key={cat.id} baseUrl={`/categorie`} />
      ));
  }

  return (
    <>
      <Helmet>
        <title>Archivio | Gian&Davi Collezioni</title>
      </Helmet>
      <Container flexDirection="column">
        <Description content={description} noTrim />
        <List>{renderListItem()}</List>
      </Container>
    </>
  );
};

export default Categories;
