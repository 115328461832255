import styled from "styled-components";

const StyledComponent = styled.div`
  padding: 0 1rem 3rem 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 80%;
`;

const SeriesContainer = ({ children }) => {
  return <StyledComponent>{children}</StyledComponent>;
};

export default SeriesContainer;
