const isEmail = (email) => {
  const match = /^[^\s@]+@[^\s@]+$/;
  return match.test(email);
};

const comparePasswords = (password, confirmation) => {
  return password === confirmation;
};

export { isEmail, comparePasswords };
