import { Wrapper, Title, Link } from "./ListIndex.styled";

const ListIndex = ({ anchors }) => {
  function handleClick(e) {
    e.preventDefault();
    document
      .getElementById(
        e.target.dataset.target.replaceAll(" ", "_").toLowerCase()
      )
      .scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }

  if (!anchors || !anchors.length) return null;

  return (
    <Wrapper>
      <Title>Indice</Title>
      {anchors.map((anchor) => (
        <Link
          key={anchor.id}
          onClick={handleClick}
          data-target={anchor.name}
          title={`Vai a ${anchor.name}`}
        >
          {anchor.name}
        </Link>
      ))}
    </Wrapper>
  );
};

export default ListIndex;
