const archive = require("assets/svg/G_D_btn__Archivio.svg").default;
const archiveHover = require("assets/svg/G_D_btn__Archivio_Roll.svg").default;
const blog = require("assets/svg/G_D_btn__Redazione.svg").default;
const blogHover = require("assets/svg/G_D_btn__Redazione_Roll.svg").default;
const dashboard = require("assets/svg/G_D_btn__Dashboard.svg").default;
const dashboardHover =
  require("assets/svg/G_D_btn__Dashboard_Roll.svg").default;
const magento = require("assets/svg/G_D_btn__Magento.svg").default;
const magentoHover = require("assets/svg/G_D_btn__Magento_Roll.svg").default;
const orders = require("assets/svg/G_D_btn__Ordini.svg").default;
const ordersHover = require("assets/svg/G_D_btn__Ordini_Roll.svg").default;

const Home = require("assets/svg/home.svg").default;

const svg = {
  archive,
  archiveHover,
  blog,
  blogHover,
  dashboard,
  dashboardHover,
  magento,
  magentoHover,
  orders,
  ordersHover,
  Home,
};

export default svg;
