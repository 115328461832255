import axios from "axios";
import { useEffect, useState } from "react";
import CategoryListItem from "./CategoryListItem";

const CategoryList = ({
  list,
  selectedCategory,
  selectCategory,
  callRefetch,
}) => {
  const [orderedItems, setItems] = useState([]);
  const [dragId, setDragId] = useState();

  useEffect(() => {
    if (list) setItems(list);
  }, [list]);

  const updateList = (newList) => {
    setItems([...newList]);
  };

  const handleDrag = async (ev) => {
    if (!dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      setDragId(target.id);
    }
  };
  const handleDrop = async (ev) => {
    if (dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      const dragBox = orderedItems.find((box) => box.id === parseInt(dragId));
      const dropBox = orderedItems.find(
        (box) => box.id === parseInt(target.id)
      );

      if (dragBox && dropBox && dragBox.parentId === dropBox.parentId) {
        const dragBoxIndex = orderedItems.indexOf(dragBox);
        const dropBoxIndex = orderedItems.indexOf(dropBox);

        const newBoxState = [...orderedItems];
        let cutOut = newBoxState.splice(dragBoxIndex, 1)[0];
        newBoxState.splice(dropBoxIndex, 0, cutOut);
        newBoxState.map((item, index) => (item.position = index));
        setItems(newBoxState);
        updateBatchCategories(newBoxState);
      }
      setDragId(null);
    }
  };

  const updateBatchCategories = async (data) => {
    for await (const cat of data) {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${cat.id}`,
        cat
      );
    }
    callRefetch();
  };

  return (
    <div style={{ maxHeight: "77%", overflow: "auto" }}>
      {orderedItems
        .sort((a, b) => a.position - b.position)
        .map((category) => (
          <CategoryListItem
            key={category.id}
            category={category}
            selectCategory={selectCategory}
            updateList={updateList}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            updateBatchCategories={(data) => updateBatchCategories(data)}
            selectedCategory={selectedCategory}
          />
        ))}
    </div>
  );
};

export default CategoryList;
