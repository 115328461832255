import { Route, Switch, useRouteMatch } from "react-router-dom";
import Categories from "./Categories";
import Category from "./Category";
import Series from "./Series";

const CategoryRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Categories />
      </Route>
      <Route path={`${path}/:slug/serie/:series`}>
        <Series />
      </Route>
      <Route path={`${path}/:slug`}>
        <Category />
      </Route>
    </Switch>
  );
};

export default CategoryRoutes;
