import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import EditSeries from "./components/EditSeries";
import SeriesContainer from "./components/SeriesContainer";
import SeriesTitle from "./components/SeriesTitle";
import SeriesList from "./components/SeriesList";
import styled from "styled-components";
import axios from "axios";
import AdminNotification from "components/common/AdminNotification";
import Loader from "components/common/Loader/Loader";
import { COLORS } from "utils";
import { darken } from "helpers";

const StyledButton = styled.div`
  height: 40px;
  display: flex;
  color: ${() => COLORS.primary};
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
  width: fit-content;
  padding-left: 1.25rem;
  font-family: "Montserrat";
  span {
    width: 1.25rem;
    height: 1.25rem;
    transition: background 0.3s ease;
    background: ${() => COLORS.primary};
    border: none;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-align: center;
    font-family: "Montserrat";
  }
  &:hover {
    span {
      background: ${darken(COLORS.primary, 10)};
    }
  }
  &:active {
    span {
      opacity: 0;
    }
  }
`;

const Series = ({ selectedCategory }) => {
  const [selectedSeries, setSeries] = useState(null);
  const [selectedId, setId] = useState(null);
  const [adminMessage, setAdminMessage] = useState(null);
  const [list, setList] = useState([]);

  const { isLoading, error, data, refetch } = useQuery(
    "relatedSeries",
    () =>
      fetch(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${selectedCategory.id}/series`
      )
        .then((res) => res.json())
        .then((data) => {
          setList(data);
          return data;
        }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const selectSeries = (id) => {
    if (selectedSeries && selectedSeries.id === id) {
      setId(null);
      setSeries(null);
    } else {
      const series = data.find((item) => item.id === id);
      setId(id);
      setSeries(series);
    }
  };

  const onCreateSeries = async () => {
    if (selectedCategory) {
      /* const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cms/categories/${selectedCategory.id}/children`
      );
      if (!res.data.length) {*/
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/cms/series`,
          {
            categoryId: selectedCategory.id,
          }
        );
        if (data) {
          selectSeries(data.id);
          refetch();
        }
      /*} else {
        setAdminMessage(
          "Impossibile creare una serie in una cartella con sotto categorie!"
        );
        setTimeout(() => {
          setAdminMessage(null);
        }, 3000);
      }*/
    }
  };

  const onUpdateSeries = async (series) => {
    const {
      name,
      thumbnail,
      shortDescription,
      longDescription,
      position,
      categoryId,
      published,
    } = series;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("thumbnail", thumbnail);
    formData.append("shortDescription", shortDescription);
    formData.append("longDescription", longDescription);
    formData.append("position", position);
    formData.append("categoryId", categoryId);
    formData.append("published", published);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/cms/series/${series.id}`,
      formData
    );
    if (data.success) {
      setAdminMessage(data.message);
      setTimeout(() => {
        setAdminMessage(null);
      }, 3000);
    }
    refetch();
  };

  const onDeleteSeries = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/cms/series/${id}`);
    if (selectedSeries && selectedSeries.id === id) {
      setId(null);
      setSeries(null);
    }
    refetch();
  };

  useEffect(() => {
    if (selectedCategory) refetch();
    else setList([]);
  }, [refetch, selectedCategory]);

  useEffect(() => {
    if (selectedId) setSeries(data.find((item) => item.id === selectedId));
    else setSeries(null);
  }, [data, selectedId]);

  if (isLoading) return <Loader />;

  if (error) return `Error: ${error.message}`;

  if (!selectedCategory) return null;

  return (
    <SeriesContainer>
      <div
        style={{
          width: "25%",
          background: darken("#f5f6fb", 2),
        }}
        onClick={() => {
          if (selectedSeries) selectSeries(selectedSeries.id);
        }}
      >
        <SeriesTitle>
          {selectedCategory ? selectedCategory.name : "Serie"}
        </SeriesTitle>
        <StyledButton onClick={(series) => onCreateSeries(series)}>
          <span>&#x2B;</span> Nuova serie
        </StyledButton>
        {list.length ? (
          <SeriesList
            onClick={(e) => e.stopPropagation()}
            list={list}
            selectSeries={selectSeries}
            selectedSeries={selectedSeries}
            deleteSeries={onDeleteSeries}
          />
        ) : null}
      </div>
      {selectedSeries && (
        <div
          style={{
            width: "75%",
            marginTop: "1.74rem",
            borderTop: "1px solid #7d8598",
          }}
        >
          <EditSeries
            series={selectedSeries}
            categoryId={selectedCategory.id}
            updateSeries={(id) => onUpdateSeries(id)}
          />
        </div>
      )}
      <AdminNotification message={adminMessage}></AdminNotification>
    </SeriesContainer>
  );
};

export default Series;
