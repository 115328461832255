import { COLORS } from "utils";
import styled from "styled-components";

const Separator = styled.div`
  color: ${COLORS.darkGray};
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 1rem;
  h2 {
    font-family: "MontserratBold";
    font-size: 1.25rem;
    margin: 0;
  }
  p {
    font-family: "Montserrat";
    font-size: 0.8rem;
    line-height: 1.5;
    margin: 1rem auto;
  }
`;

const ArticleListItem = ({ article: { name, description } }) => {
  return (
    <Separator id={name.replaceAll(" ", "_").toLowerCase()}>
      <h2>{name}</h2>
      <p>{description}</p>
    </Separator>
  );
};

export default ArticleListItem;
