export const COLORS = {
  // SYSTEM
  black: "#000000",
  white: "#ffffff",
  transparent: "transparent",
  success: "#27A645",
  warning: "#EF9300",
  error: "#ff0000",
  // THEME
  primary: "#00abf0",
  // GRAYSCALE
  gray: "#BBC8E3",
  darkGray: "#7d8598",
  lightGray: "#f5f6fb",
  blue: "#1080C7",
};

export const FONTS = {
  // HEADINGS
  h1: "'AderirekSans',Helvetica,Arial,sans-serif",
  h2: "'AderirekSans',Helvetica,Arial,sans-serif",
  h3: "'AderirekSans',Helvetica,Arial,sans-serif",
  h4: "'AderirekSans',Helvetica,Arial,sans-serif",
  // BODY
  body: "'SFPRODisplayRegular',Helvetica,Arial,sans-serif",
  small: "'SFPRODisplayRegular',Helvetica,Arial,sans-serif",
  bold: "'SFPRODisplayBold',Helvetica,Arial,sans-serif",
};

const Theme = { COLORS, FONTS };

export default Theme;
