import { Container } from "components";
import SignupForm from "./components/SignupForm";

const Signup = () => {
  return (
    <Container flexDirection="column">
      <SignupForm />
    </Container>
  );
};

export default Signup;
