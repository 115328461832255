import { COLORS, FONTS } from "utils";
import styled from "styled-components";
import { lighten, darken } from "helpers";

const Generic = styled.div`
  align-items: center;
  background: ${darken(COLORS.lightGray, 5)};
  color: ${COLORS.darkGray};
  display: flex;
  font: ${FONTS.small};
  justify-content: center;
  margin: 0;
  padding: 1rem;
  width: 100%;
`;

const Success = styled(Generic)`
  background: ${lighten(COLORS.success, 40)};
  color: ${COLORS.success};
`;
const Warning = styled(Generic)`
  background: ${lighten(COLORS.warning, 40)};
  color: ${COLORS.warning};
`;
const Error = styled(Generic)`
  background: ${lighten(COLORS.error, 40)};
  color: ${COLORS.error};
`;
const Info = styled(Generic)`
  background: ${darken(COLORS.lightGray, 5)};
  color: ${COLORS.darkGray};
`;

const Alert = ({ message, type }) => {
  switch (type) {
    case "error":
      return <Error>{message}</Error>;
    case "success":
      return <Success>{message}</Success>;
    case "warning":
      return <Warning>{message}</Warning>;
    case "info":
      return <Info>{message}</Info>;
    default:
      return <Generic>{message}</Generic>;
  }
};

export default Alert;
