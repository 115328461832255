import Navigation from "./Navbar.styled";

const Navbar = () => {
  const links = [
    {
      id: 1,
      label: "puffi",
      url: "https://gianedavicollezioni.com/puffi.html",
    },
    {
      id: 2,
      label: "tintin",
      url: "https://gianedavicollezioni.com/tintin.html",
    },
    {
      id: 3,
      label: "sorpresine",
      url: "https://gianedavicollezioni.com/sorpresine.html",
    },
    {
      id: 4,
      label: "vinyl toys",
      url: "https://gianedavicollezioni.com/vinil-toys-2.html",
    },
    {
      id: 5,
      label: "carte e figurine",
      url: "https://gianedavicollezioni.com/carte-e-figurine.html",
    },
    {
      id: 6,
      label: "pvc",
      url: "https://gianedavicollezioni.com/pvc.html",
    },
    {
      id: 7,
      label: "giocattoli e modellismo",
      url: "https://gianedavicollezioni.com/giocattoli-e-modellismo.html",
    },
    {
      id: 8,
      label: "costruzioni",
      url: "https://gianedavicollezioni.com/costruzioni.html",
    },
    {
      id: 9,
      label: "collezionismo",
      url: "https://gianedavicollezioni.com/collezionismo.html",
    },
    {
      id: 10,
      label: "altro",
      url: "https://gianedavicollezioni.com/altro.html",
    },
  ];

  if (!links || !links.length) return null;

  return (
    <Navigation>
      <ul>
        {links.map((link) => {
          return (
            <li key={link.id}>
              <a href={link.url}>{link.label}</a>
            </li>
          );
        })}
      </ul>
    </Navigation>
  );
};

export default Navbar;
