import styled from "styled-components";

const StyledComponent = styled.h2`
  margin-top: 0;
  color: #00abf0;
  padding: 0 0 0.5rem 1rem;
  font-family: "MontserratBold";
  font-size: 1rem;
  border-bottom: 1px solid #7d8598;
  background: #f5f6fb;
  display: flex;
  align-items: center;
`;

const SeriesTitle = ({ children }) => {
  return <StyledComponent>{children}</StyledComponent>;
};

export default SeriesTitle;
