// DEPENDENCIES
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
// UTILS
import { COLORS } from "utils";
import { darken } from "helpers";
// ASSETS
import placeholder from "assets/img/placeholder_immagine.png";

const Container = styled.div`
  align-items: center;
  border: none;
  border-radius: 1rem;
  color: #7d8598;
  display: flex;
  flex: 1;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  outline: none;
  padding: 0.25rem 1rem;
  & div {
    border-radius: 10px;
    height: 6rem;
    overflow: hidden;
    position: relative;
    width: 6rem;
    & img {
      height: auto;
      width: 100%;
    }
    & input {
      cursor: pointer;
      inset: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
    }
    &:last-of-type {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      span {
        color: ${() => COLORS.darkGray};
        font-family: "Montserrat";
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
        overflow-wrap: break-word;
        width: 100%;
        word-break: break-all;
      }
      button {
        background: ${() => COLORS.gray};
        border: none;
        border-radius: 1rem;
        color: ${() => COLORS.darkGray};
        cursor: pointer;
        font-family: "Montserrat";
        font-size: 0.7rem;
        margin: 0;
        margin-bottom: 1rem;
        padding: 0.25rem 1rem;
        transition: background 0.3s ease;
        width: fit-content;
        &:hover {
          background: ${() => darken(COLORS.gray, 10)};
        }
      }
    }
    &:last-of-type {
      padding-left: 1rem;
    }
  }
`;

const EditCategoryPreview = ({ name, thumbnail, inputName, emitImage }) => {
  const [file, setFile] = useState({
    name: "",
    url: thumbnail,
  });
  const ref = useRef(null);

  useEffect(() => {
    setFile({
      name: "",
      url: thumbnail,
    });
  }, [thumbnail]);

  return (
    <Container>
      <div>
        <img src={file.url || placeholder} alt={name} />
        <input
          type="file"
          name={inputName}
          ref={ref}
          onChange={(e) => {
            const newFile = {
              file: e.target.files[0],
              name: e.target.files[0].name,
              url: URL.createObjectURL(e.target.files[0]),
            };
            emitImage(inputName, newFile);
            setFile(newFile);
          }}
        />
      </div>
      <div>
        <span title={file.name}>{file.name}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (ref.current) ref.current.click();
          }}
        >
          Scegli
        </button>
      </div>
    </Container>
  );
};

export default EditCategoryPreview;
