import { Container } from "components";
import { Table } from "components/common/Cms";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "services/user.service";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function getUsers() {
      const { data } = await getAllUsers();
      if (data.success) setUsers(data.users);
    }
    getUsers();
  }, []);

  function renderPagination() {
    if (users.length > 20) {
      return (
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
        </ul>
      );
    }
  }

  return (
    <Container>
      <Table
        data={users}
        headers={["ID", "Email", "Nome", "Cognome", "Ruolo", "Creato il"]}
      />
      {renderPagination()}
    </Container>
  );
};

export default AdminUsers;
