import { COLORS } from "utils";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { darken } from "helpers";
import SeriesMessage from "../components/SeriesMessage";
import EditArticle from "./EditArticle";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  user-select: none;
`;

const StyledButton = styled.div`
  margin-top: 1rem;
  display: flex;
  color: ${() => COLORS.primary};
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
  flex: 0;
  width: fit-content;
  padding-left: 1.25rem;
  span {
    width: 20px;
    height: 20px;
    transition: background 0.3s ease;
    background: ${() => COLORS.primary};
    border: none;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "MontserratBold";
    font-size: 1.5rem;
    line-height: 0;
    padding-bottom: 0.125rem;
    &:hover {
      background: ${darken(COLORS.primary, 10)};
    }
    &:active {
      opacity: 0;
    }
  }
`;
const SubComponent = ({
  series,
  articles,
  deleteArticle,
  updateBatch,
  updateArticle,
  createArticle,
  createSeparator,
  reorderArticles,
  emitGallery,
}) => {
  const [orderedItems, setItems] = useState([]);
  const [dragId, setDragId] = useState();

  const listRef = useRef(null);

  useEffect(() => {
    if (articles) {
      const newList = [...articles];
      setItems([...newList]);
    }
  }, [articles]);

  const handleDrag = async (ev) => {
    if (!dragId) {
      const target = ev.target.id ? ev.target : ev.target.closest("div");
      setDragId(target.id);
    }
  };

  const handleDrop = async (ev) => {
    if (dragId) {
      const target = ev.target.id
        ? ev.target
        : ev.target.closest("div[name='draggable']");
      const dragBox = orderedItems.find((box) => box.id === parseInt(dragId));
      const dropBox = orderedItems.find(
        (box) => box.id === parseInt(target.id)
      );

      if (dragBox && dropBox && dragBox.parentId === dropBox.parentId) {
        const dragBoxIndex = orderedItems.indexOf(dragBox);
        const dropBoxIndex = orderedItems.indexOf(dropBox);

        const newBoxState = [...orderedItems];
        let cutOut = newBoxState.splice(dragBoxIndex, 1)[0];
        newBoxState.splice(dropBoxIndex, 0, cutOut);
        newBoxState.map((item, index) => (item.position = index));
        setItems(newBoxState);
        updateBatch(newBoxState);
      }
      setDragId(null);
    }
  };

  if (!orderedItems.length)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <SeriesMessage>
          Al momento non sono presenti articoli in questa serie
        </SeriesMessage>
        <StyledButton
          onClick={() => {
            createArticle();
            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 100);
          }}
        >
          <span>+</span> Aggiungi articolo
        </StyledButton>
      </div>
    );

  return (
    <div
      style={{
        width: "100%",
        userSelect: "none",
        // height: "40%",
        overflow: "auto",
        marginBottom: "2rem",
      }}
    >
      <StyledComponent ref={listRef}>
        {orderedItems
          .sort((a, b) => a.position - b.position)
          .map((article) => (
            <EditArticle
              key={`${article.id}-${article.name}`}
              article={article}
              deleteArticle={deleteArticle}
              updateArticle={updateArticle}
              seriesId={series.id}
              siblings={orderedItems.length}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
              reorderArticles={reorderArticles}
              emitGallery={emitGallery}
            />
          ))}
        <StyledButton
          onClick={() => {
            createArticle();
            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 100);
          }}
        >
          <span>+</span> Aggiungi articolo
        </StyledButton>
        <StyledButton
          onClick={() => {
            createSeparator();
            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 100);
          }}
        >
          <span>+</span> Aggiungi separatore
        </StyledButton>
      </StyledComponent>
    </div>
  );
};

const ArticleList = React.memo(SubComponent);
export default ArticleList;
